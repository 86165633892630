import { useEffect } from 'react';
import { cloneDeep } from 'lodash-es';
import type {
  EnvironmentKey,
  Entity,
  EntityDetail,
  EntityRootData,
} from '@web-config-app/core';
import { Operation } from '@web-config-app/core';
import { API_JSON_CONTENT_TYPE } from '@web-config-app/api';
import {
  UseEntityGetOptions,
  useEntityGet,
} from '../use-entity-get/use-entity-get';
import {
  UseEntityUpdateOptions,
  useEntityUpdate,
} from '../use-entity-update/use-entity-update';
import {
  UseEntityCreateOptions,
  useEntityCreate,
} from '../use-entity-create/use-entity-create';
import { useEntityDuplicate } from '../use-entity-duplicate/use-entity-duplicate';

export interface UseEntityOperationsProps {
  entity: Entity;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  entityRootData: EntityRootData | undefined;
  onEntityGetSuccess?: (
    data: EntityDetail,
    included: EntityDetail[] | undefined,
  ) => void;
  options?: {
    get?: UseEntityGetOptions;
    update?: UseEntityUpdateOptions;
    create?: UseEntityCreateOptions;
    duplicateAsDraft?: UseEntityCreateOptions;
  };
  environment: EnvironmentKey;
}

export type UseEntityOperationsResult = ReturnType<typeof useEntityOperations>;

/**
 * Provides a convenience composition of all of an entity instance's data operations
 * like GET, UPDATE, DELETE, CREATE and returns them in a single object.
 *
 */

export const useEntityOperations = ({
  entity,
  instanceId,
  entityRootData,
  onEntityGetSuccess,
  options,
  environment,
}: UseEntityOperationsProps) => {
  // GET Operations
  const get = useEntityGet({
    entity,
    instanceId,
    environment,
    options: {
      getIncludedEntities: true,
      ...options?.get,
    },
  });

  // POST Operations
  const create = useEntityCreate({
    entity,
    environment,
    data: entityRootData,
    options: options?.create,
    headers: {
      'Content-Type': API_JSON_CONTENT_TYPE,
    },
    operation: Operation.Create,
  });

  const duplicateAsDraft = useEntityDuplicate({
    entity,
    environment,
    data: entityRootData,
    options: options?.duplicateAsDraft,
  });

  // PATCH Operations
  const update = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Update,
  });

  const publish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Publish,
  });

  const republish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Republish,
  });

  const unpublish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Unpublish,
  });

  const archive = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Archive,
  });

  const unarchive = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: Operation.Unarchive,
  });

  /**
   * on successful get, refresh the entityData
   */
  useEffect(() => {
    if (get.data && onEntityGetSuccess) {
      // By cloning the data from the GET call, it removes changes directly to the api response due to prop reference, keeping the response unchanged and pure.
      const isolatedData = cloneDeep(get?.data);
      onEntityGetSuccess(isolatedData, get.included);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get.data, get.included]);

  return {
    get,
    update,
    create,
    publish,
    republish,
    unpublish,
    archive,
    unarchive,
    duplicateAsDraft,
  };
};
