import { CreateTenantConfigInfo } from '@web-config-app/core';
import { manulifeCampaignRulesTemplates } from './rules-templates';
import { MANULIFE_STRINGS_EN } from './strings';

export const manulifeConfig: CreateTenantConfigInfo = {
  strings: {
    en: MANULIFE_STRINGS_EN,
  },
  rulesTemplates: manulifeCampaignRulesTemplates,
};
