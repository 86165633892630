import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import type { EntityReferenceControlSchema } from '../../types/controls';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';

export const useEntityReferenceSchemaType = ({
  schema,
}: {
  schema: EntityReferenceControlSchema;
}) => {
  const { relationship } = schema['x-entity-reference'];

  const { rootSchema } = useEntityDetailsProps();

  const { type: entityType } = getRelationshipSchemaItemType(
    relationship,
    rootSchema,
  );

  return entityType;
};
