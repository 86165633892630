import { useCallback } from 'react';
import type {
  EnvironmentKey,
  Entity,
  EntityRootData,
} from '@web-config-app/core';
import { Operation } from '@web-config-app/core';
import { API_JSON_CONTENT_TYPE } from '@web-config-app/api';
import {
  UseEntityGetOptions,
  useEntityGet,
} from '../use-entity-get/use-entity-get';
import {
  UseEntityCreateOptions,
  useEntityCreate,
} from '../use-entity-create/use-entity-create';
import { useSyncReferencedAssetEntities } from '../use-sync-referenced-asset-entities/use-sync-referenced-asset-entities';

export interface UseMultiEnvironmentEntityOperationsProps {
  entity: Entity;
  entityData: EntityRootData | undefined;
  instanceId: string;
  options?: {
    get?: UseEntityGetOptions;
    copyToEnvironment?: UseEntityCreateOptions;
  };
  targetEnvironment: EnvironmentKey;
  sourceEnvironment: EnvironmentKey;
}

export type UseMultiEnvironmentEntityOperationsResult = ReturnType<
  typeof useMultiEnvironmentEntityOperations
>;

/**
 * A custom hook that manages operations for entities across multiple environments.
 * It handles fetching entity data, copying entities between environments, and ensures referenced assets
 * are properly synchronized between environments before copying.
 *
 * @param props - The properties for multi-environment entity operations
 * @param props.entity - The entity to perform operations on
 * @param props.instanceId - The unique identifier for the entity instance
 * @param props.entityData - The root data of the entity
 * @param props.options - Optional configuration for get and copy operations
 * @param props.options.get - {@link  UseEntityGetOptions} Options for entity retrieval
 * @param props.options.copyToEnvironment - {@link UseEntityCreateOptions} Options for copying entity to target environment
 * @param props.targetEnvironment - The environment ID where the entity will be copied to
 * @param props.sourceEnvironment - The environment ID where the entity is being copied from
 *
 * @returns An object containing:
 * - entityStatus: The current status of the entity in the target environment
 * - copyToEnvironment: A function that synchronizes assets and copies the entity to the target environment
 *
 * @example
 * const { entityStatus, copyToEnvironment } = useMultiEnvironmentEntityOperations({
 *   entity,
 *   instanceId,
 *   entityData,
 *   targetEnvironment: 'production',
 *   sourceEnvironment: 'staging'
 * });
 */

export const useMultiEnvironmentEntityOperations = ({
  entity,
  instanceId,
  entityData,
  options,
  targetEnvironment,
  sourceEnvironment,
}: UseMultiEnvironmentEntityOperationsProps) => {
  const get = useEntityGet({
    entity,
    instanceId,
    environment: targetEnvironment,
    options: {
      getIncludedEntities: true,
      retry: false,
      ...options?.get,
    },
  });

  const { mutate: copyEntityMutate } = useEntityCreate({
    entity,
    data: entityData,
    options: options?.copyToEnvironment,
    headers: {
      'Content-Type': API_JSON_CONTENT_TYPE,
    },
    operation: Operation.CopyToEnvironment,
    environment: targetEnvironment,
  });

  const copyAssetsToEnvironment = useSyncReferencedAssetEntities({
    schema: entity.schema,
    sourceEnvironment,
    targetEnvironment,
  });

  /**
   * syncAssetsAndCopyAction will check that all assets referenced in the entity being copied already
   * exist on the target environment. If any are missing, we copy them first before copying the entity.
   */
  const syncAssetsAndCopyAction = useCallback(async () => {
    const copyAssets = await copyAssetsToEnvironment(entityData);

    if (copyAssets.status === 'error') {
      const errors = copyAssets.results.filter(
        (result) => result.status === 'error',
      );

      /**
       * TODO display an error banner after the assets have copied
       * if there was any errors during the copy process.
       *
       * https://everlong.atlassian.net/browse/CACT-1650
       */
      console.error(
        `Failed to copy assets to ${targetEnvironment} environment`,
        errors,
      );
    }

    copyEntityMutate();
  }, [
    entityData,
    copyAssetsToEnvironment,
    copyEntityMutate,
    targetEnvironment,
  ]);

  return {
    entityStatus: get.data?.attributes?.entityMetadata?.status,
    copyToEnvironment: syncAssetsAndCopyAction,
  };
};
