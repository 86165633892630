import { CreateTenantConfigInfo } from '@web-config-app/core';
import { highmarkActivityRulesTemplates } from './rules-templates';
import { HIGHMARK_STRINGS_EN } from './strings';

export const highmarkIELConfig: CreateTenantConfigInfo = {
  strings: {
    en: HIGHMARK_STRINGS_EN,
  },
  rulesTemplates: highmarkActivityRulesTemplates,
};
