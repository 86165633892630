export const EN_STRING_NODE_OVERRIDES = {
  NODES: 'Nodes',
  RESOLVERS: 'Resolvers',
  NODE_ENTITY_NAME: 'Node',
  NODE_DETAILS_ENTITY_LABEL: 'Node details',
  NODE_DETAILS_ENTITY_HINT:
    'The details section is where you can set some primary information about a node that makes up and powers the widget or page you would like to configure. The content in this section is not visible to end users.',
  NODE_DETAILS_NODE_ID_ENTITY_LABEL: 'Node ID',
  NODE_DETAILS_NODE_ID_ENTITY_HINT:
    'This will not be visible to end user and it should be unique',
  NODE_DETAILS_GROUPING_ENTITY_LABEL: 'Node grouping',
  NODE_DETAILS_GROUPING_ENTITY_HINT:
    'Select which capability this node should belong to. This will be used to group it under the right domain.',
  NODE_DETAILS_IS_NEW_GROUPING_ENTITY_LABEL: 'Create new grouping',
  NODE_DETAILS_NEW_GROUPING_ENTITY_LABEL: 'New grouping',
  NODE_DETAILS_NEW_GROUPING_ENTITY_HINT:
    'Set which domain you’d like to add this node under. If you want to set an additional level, separate each level with a $ symbol. Note that capitalization will affect the grouping. e.g., MyCapability$Subgroup',
  NODE_GROUPING_HOMESCREEN_OPTION: 'Homescreen',
  NODE_GROUPING_ABOUT_ME_OVERVIEW_OPTION: 'About me overview',
  NODE_GROUPING_ABOUT_ME_BUILD_YOUR_HEALTH_PROFILE_OPTION:
    'About me build your health profile',
  NODE_GROUPING_ABOUT_ME_RECENT_ACHIEVEMENTS_OPTION:
    'About me recent achievements',
  NODE_GROUPING_GET_CARE_LANDING_PAGE_OPTION: 'Get acre landing page',
  NODE_GROUPING_GET_CARE_OPTIONS_OPTION: 'Get care options',
  NODE_GROUPING_CREATE_NEW_OPTION: 'Create new grouping',
  NODE_DETAILS_NODE_NAME_ENTITY_LABEL: 'Node Name',
  NODE_DETAILS_NODE_NAME_ENTITY_HINT:
    'This will not be visible to end user and it should be unique enough to easily reference this node',
  NODE_DETAILS_NODE_DESCRIPTION_ENTITY_LABEL: 'Node Description',
  NODE_DETAILS_NODE_DESCRIPTION_ENTITY_HINT:
    'The node description can be used to further differentiate similar nodes',
  NODE_DETAILS_OBSERVABILITY_BANNER_TITLE: 'Observability',
  // @TODO - Add grafana link to this translation string when CACT will support it - CACT-1326
  NODE_DETAILS_OBSERVABILITY_BANNER_DESCRIPTION:
    'If you want to view observability metrics for this node, you can see those on the Grafana dashboard.',
  NODE_DETAILS_MONITORING_DETAILS_ENTITY_LABEL: 'Monitoring details',
  NODE_DETAILS_NODE_OWNER_ENTITY_LABEL: 'Node Owner',
  NODE_DETAILS_NODE_OWNER_ENTITY_HINT:
    'This should reflect the team who should be notified with observation data about this node.',
  MONITORING_TIER_OPTION_REGULAR: 'Regular',
  MONITORING_TIER_OPTION_BEST_EFFORT: 'Best-effort',
  MONITORING_TIER_OPTION_OFFLINE: 'Offline',
  NODE_DETAILS_MONITORING_TIER_ENTITY_LABEL: 'Monitoring Tier',
  NODE_DETAILS_MONITORING_TIER_ENTITY_HINT:
    'The tier is related to our SLOs. We have multiple levels of SLO. Each one of these have a threshold for errors or latency before an alert is sent.',
  NODE_DETAILS_ENABLE_CACHING_ENTITY_LABEL: 'Enable caching for this node',
  NODE_DETAILS_CACHING_ENTITY_LABEL: 'Caching',
  NODE_DETAILS_CACHING_ENTITY_HINT:
    "Caching stores a copy of the content on the server so it doesn't need to be fetched from the source every time. Choose a longer duration for content that doesn't change often.",
  NODE_DETAILS_CACHING_STRATEGY_LABEL: 'Caching strategy',
  NODE_DETAILS_CACHING_ENTITY_ADD_BUTTON_TEXT: 'Add another caching strategy',
  NODE_DETAILS_TIME_TO_LIVE_VALUE_LABEL: 'Time to live value',
  NODE_DETAILS_TIME_TO_LIVE_DURATION_LABEL: 'Time to live duration',
  // FILTERS PAGE
  NODE_FILTERS_LABEL: 'Filters',
  NODE_FILTERS_HINT:
    'Setting filters allows you to personalize who can and cannot view this node. To select what data is available to filter on, you must first choose a resolver. This is the mechanism by which we connect a data source to Masonry. You can select as many resolvers as you need to get the right combination of data you want to build your filter on. \n Alternatively, you can skip this step if you want this node to be visible by everybody.',
  NODE_FILTER_IS_FILTER_APPLIED: 'Apply filters to this node',
  NODE_FILTERS_RESOLVER_LABEL: 'Resolvers',
  NODE_FILTERS_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  NODE_FILTER_DEFINITION_LABEL: 'Filter definition',
  'Contentful Entry Id': 'Contentful Entry Id',
  NODE_FILTER_DEFINITION_HINT:
    'In this section, you will use the data points coming from the resolvers you’ve selected to create a filter using json. This will determine who can and can’t see the node you are currently configuring. You must first declare which variables you will use in your filter definition, and then you can use them in your filter rule',
  NODE_FILTER_DEFINITION_VARIABLES_DEFINITION_LABEL: 'Define Variables',
  NODE_FILTER_DEFINITION_VARIABLE_LABEL: 'Variables',
  NODE_FILTER_DEFINITION_VARIABLE_HINT:
    'Variables are what you will reference in your filter rule. These either need to map back to one of the resolver data points you have selected for this node, or will need to have a value provided for them. Alternatively, you can set an advanced mapping where you can manually write a mapping configuration using GJSON.',
  NODE_FILTER_DEFINITION_VARIABLE_TYPE_OPTION: 'Select Variable Type',
  FILTER_DEFINITION_SIMPLE_VARIABLE_MAPPING_OPTION_LABEL: 'Map variables',
  FILTER_DEFINITION_VARIABLE_LABEL: 'Map Variable',
  FILTER_DEFINITION_GJSON_VARIABLE_OPTION_LABEL: 'Advanced mapping using GJSON',
  MAP_VARIABLE_TO_DATAPOINT_OPTION_KEY: 'Map to datapoint',
  MAP_VARIABLE_TO_DATAPOINT_OPTION_LABEL: 'Map to datapoint',
  GJOSN_VARIABLE_LABEL: 'Advanced mapping with GJSON',
  GJOSN_VARIABLE_HINT:
    'This setting allows you to set multi-level complex mapping for this variable in code using GJSON',
  FILTER_DEFINITION_FILTER_RULE_LABEL: 'Filter Rule',
  FILTER_DEFINITION_FILTER_RULE_HINT:
    'Choose when and to whom this node will appear by setting filtering rules. You can add together multiple rules. The rules must be written as a jsonlogic expression.  If left empty, this node will show to everybody.',
  DUMMY_FILTER_RULE_MASONRY: 'Dummy Filter Rule',
  FILTER_DEFINITION_VARIABLES_LABEL: 'Variables',
  MAP_VARIABLE_TO_CUSTOM_VALUE_OPTION_KEY: 'Manually set variable',
  MAP_VARIABLE_NAME_OPTION_LABEL: 'Variable Name',
  MAP_VARIABLE_TO_CUSTOM_VALUE_KEY: 'Set a value',
  MAP_VARIABLE_TO_CUSTOM_VALUE_HINT:
    'This setting allows you to set a value manually for this variable instead of mapping it back to one of the datapoints you selected.',
  // STATES PAGE
  STATE_PAGE_LABEL: 'States',
  STATE_PAGE_HINT:
    'Set which states you need to configure for this node. All nodes must support a default state, but you can optionally support an error or loading state as well for this node.',
  DEFAULT_STATE_PAGE_LABEL: 'Default state configuration',
  DEFAULT_STATE_PAGE_HINT:
    'Set how you would like the default state of this node to display and how it should be configured. If you choose a composed component that can accept children, you will get additional form elements to populate it’s children as well.',
  LOADING_STATE_PAGE_LABEL: 'Loading state configuration',
  LOADING_STATE_PAGE_HINT:
    'Set how you would like the loading state of this node to display and how it should be configured. This will enable asynchronous loading parameters to be set. This is recommended for a better user experience when network connections may be poor.',
  ERROR_STATE_PAGE_LABEL: 'Error state configuration',
  ERROR_STATE_PAGE_HINT:
    'Set how you would like the error state of this node to display and how it should be configured. If you do not set an error state, nothing will appear if an error appears in the back-end',
  // Default state page
  NODE_STATE_COMPONENT_TYPE_LABEL: 'UI Component type',
  NODE_STATE_COMPONENT_TYPE_HINT:
    'Select a UI component you would like to use for the display of this node',
  NODE_STATE_NAMESPACE_LABEL: 'Component namespace',
  NODE_STATE_NAMESPACE_HINT:
    'If you have a specific way you’d like this component to display, you can specify which namespace contains the correct version of this component (in case of overrides)',
  NODE_STATE_SELECT_NODE_TYPE: 'Select UI Component',
  NODE_RESOLVER_LABEL: 'Resolvers',
  NODE_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  NODE_DEFAULT_STATE_RESOLVER_LABEL: 'Resolvers',
  NODE_DEFAULT_STATE_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  INSIGHT_CARD_RENDERER_LABEL: 'Insight Card',
  LARGE_CARD_RENDERER_LABEL: 'Large Card',
  SMALL_CARD_RENDERER_LABEL: 'Small Card',
  UI_COMPONENT_DEFAULT_STATE_PROPERTIES_LABEL: 'Default state properties',
  UI_COMPONENT_DEFAULT_STATE_PROPERTIES_HINT:
    'To set the properties for the default state of this UI Component, you will first select which resolvers contain the content data you want, and then select the specific data points from that resolver you’d like to import. After that, you’re going to map it to the elements within this component that can accept content.',
  UI_COMPONENT_LOADING_STATE_PROPERTIES_LABEL: 'Loading state properties',
  UI_COMPONENT_LOADING_STATE_PROPERTIES_HINT:
    'To set the properties for the loading state of this UI Component, you will first select which resolvers contain the content data you want, and then select the specific data points from that resolver you’d like to import. After that, you’re going to map it to the elements within this component that can accept content.',
  UI_COMPONENT_PROPERTY_HEADING_LABEL: 'Heading',
  UI_COMPONENT_PROPERTY_ACTION_LABEL: 'Action',
  UI_COMPONENT_PROPERTY_ACTIONALTTEXT_LABEL: 'Action Alt Text',
  INSIGHT_CARD_PROPERTY_CALLOUT_LABEL: 'Callout',
  UI_COMPONENT_PROPERTY_BODY_LABEL: 'Body',
  UI_COMPONENT_PROPERTY_IMAGE_LABEL: 'Image',
  UI_COMPONENT_PROPERTY_IMAGEALTTEXT_LABEL: 'Image Alt Text',
  UI_COMPONENT_PROPERTY_EYEBROW_LABEL: 'Eyebrow',
  INSIGHT_CARD_PROPERTY_CALLOUT_BACKGROUNDCOLOR_LABEL: 'Background Color',
  UI_COMPONENT_PROPERTY_CURRENTTEXT_LABEL: 'Current Text',
  UI_COMPONENT_PROPERTY_TEXTLINEONE_LABEL: 'Text line 1',
  UI_COMPONENT_PROPERTY_TEXTLINETWO_LABEL: 'Text line 2',
  UI_COMPONENT_PROPERTY_STATUSTEXT_LABEL: 'Status text',
  UI_COMPONENT_PROPERTY_STATUS_LABEL: 'Status label',
  UI_COMPONENT_PROPERTY_HIDECHEVRON_LABEL: 'Hide chevron',
  UI_COMPONENT_PROPERTY_AMOUNT_LABEL: 'Amount',
  UI_COMPONENT_ACTION_LABEL: 'Actions',
  UI_COMPONENT_ACTION_HINT:
    'Actions are how interactions are handled on this node. The type of action that can be configured depends on the component type you’ve chosen.',
  UI_COMPONENT_PROPERTY_INDICATOR_LABEL: 'Indicator Label',
  UI_COMPONENT_PROPERTY_SIZE_LABEL: 'Size',
  UI_COMPONENT_PROPERTY_TEXT_LABEL: 'Text',
  UI_COMPONENT_PROPERTY_VARIANT_LABEL: 'Variant',
  UI_COMPONENT_PROPERTY_VERTICALBREAKPOINT_LABEL: 'Vertical breakpoint',
  UI_COMPONENT_PROPERTY_SIDEBARSIZE_LABEL: 'Sidebar size',
  UI_COMPONENT_PROPERTY_SIDEBARSIDE_LABEL: 'Side',
  UI_COMPONENT_PROPERTY_ISSEMANTICLIST_LABEL: 'Is semantic list',
  UI_COMPONENT_PROPERTY_SUBHEADING_LABEL: 'Sub heading',
  UI_COMPONENT_PROPERTY_LEFTIMAGE_LABEL: 'Left image label',
  UI_COMPONENT_PROPERTY_RIGHTIMAGE_LABEL: 'Right image label',
  UI_COMPONENT_PROPERTY_URL_LABEL: 'URL label',
  ITEMS: 'Items',
  CAROUSEL: 'Carousel',
  CALL_TO_ACTION_CARD: 'CTA Card',
  EXTRA_SMALL_VISUAL_CARD: 'Extra Small Card',
  HERO_BANNER: 'Hero Banner',
  HORIZONTAL_BAR_CHART: 'Horizontal Bar Chart',
  GRID_LAYOUT: 'Grid Layout',
  VERTICAL_LAYOUT: 'Vertical Layout',
  HORIZONTAL_LAYOUT: 'Horizontal Layout',
  IMAGE_BANNER: 'Image Banner',
  INSIGHT_CARD: 'Insight Card',
  LARGE_CARD: 'Large Card',
  METRIC_CARD: 'Metric Card',
  LOADER: 'Loader',
  MICRO_CARD: 'Micro Card',
  NOTIFICATION_BANNER: 'Notification Banner',
  NOTIFICATION_CARD: 'Notification Card',
  PRODUCT_CARD: 'Product Card',
  PROGRESS_BAR: 'Progress Bar',
  PROGRESS_RING: 'Progress Ring',
  SECTION_HEADER: 'Section Header',
  SMALL_CARD: 'Small Card',
  CLAIM_CARD: 'Claim Card',
  PROGRAMCARD: 'Program Card',
  TABS: 'Tabs',
  VERTICAL_BAR_CHART: 'Vertical Bar Chart',
  HIDDEN: 'Hidden',
  SIDEBAR_LAYOUT: 'Sidebar layout',
  AUTHORIZATION_LEARN_MORE_CARD: 'Authorization learn more card',
  AUTHORIZATION_LEARN_MORE_STATUS_HEADER: 'Authorization learn more header',
  AUTHORIZATION_STATUS_LEARN_MORE_DEFINITION:
    'Authorization status learn more definition',
  CURRENT_TEXT_LABEL: 'Current Text',
  HABIT_GRID_CARD: 'Habit grid card',
  LOAD_MORE_BUTTON: 'Load more button',
  VIDEO: 'Video',
  PAGINATION_LAYOUT: 'Pagination layout',
  NOTIFICATION_DETAILS_HEADING: 'Notification details heading',
  NOTIFICATION_DETAILS_FOOTER: 'Notification details footer',
  NOTIFICATION_DETAILS_BODY: 'Notification details body',
  NO_DATA: 'No data',
  STATE_SELECT_MAPPING_TYPE_LABEL: 'Select Mapping Type',
  STATE_SELECT_MAPPING_TYPE_RESOLVER_DATAPOINT: 'Map to a resolver datapoint',
  STATE_SELECT_MAPPING_TYPE_ENTER_MANUALLY: 'Enter Manually',
  STATE_SELECT_MAPPING_TYPE_MAP_WITH_GJSON: 'Advanced mapping with GJSON',
  STATE_MAP_PROPERTY_TO_DATAPOINT: 'Datapoint',
  STATE_MAP_PROPERTY_TO_MANUAL_VALUE: 'Value',
  STATE_MAP_PROPERTY_TO_GJSON_LABEL: 'Mapping definition',
  STATE_MAP_PROPERTY_TO_GJSON_HINT:
    'This setting allows you to manually write in a mapping definition using GJSON',
  UI_COMPONENT_PROPERTY_BUTTONLABEL_LABEL: 'Button Label',
  UI_COMPONENT_PROPERTY_LABEL_LABEL: 'Label',
  UI_COMPONENT_PROPERTY_CURVED_LABEL: 'Curved',
  UI_COMPONENT_PROPERTY_ICON_LABEL: 'Icon',
  UI_COMPONENT_PROPERTY_INCLUDESAFEAREA_LABEL: 'Include Safe Area',
  UI_COMPONENT_PROPERTY_FOOTERTEXT_LABEL: 'Footer Text',
  UI_COMPONENT_PROPERTY_HEADINGLEVEL_LABEL: 'Heading Level',
  UI_COMPONENT_PROPERTY_PROGRESS_LABEL: 'Progress',
  UI_COMPONENT_PROPERTY_SUFFIXTEXT_LABEL: 'Suffix Text',
  UI_COMPONENT_PROPERTY_HEADERBUTTONLABEL_LABEL: 'Header Button Label',
  UI_COMPONENT_PROPERTY_SPACING_LABEL: 'Spacing',
  UI_COMPONENT_PROPERTY_ACTIONTEXT_LABEL: 'Action Text',
  UI_COMPONENT_PROPERTY_BACKGROUNDCOLOR_LABEL: 'Background Color',
  UI_COMPONENT_PROPERTY_BADGES_LABEL: 'Badges',
  BADGES: 'Badges',
  MEASUREMENTS: 'Measurements',
  TICKMARKS: 'Tickemarks',
  UI_COMPONENT_PROPERTY_STYLE_LABEL: 'Style',
  UI_COMPONENT_PROPERTY_ACCESSIBILITYLABEL_LABEL: 'Accessibility label',
  UI_COMPONENT_PROPERTY_TICKMARKS_LABEL: 'Tickemarks',
  UI_COMPONENT_PROPERTY_DESCRIPTION_LABEL: 'Description',
  UI_COMPONENT_PROPERTY_FOOTERBUTTONLABEL_LABEL: 'Footer Button Label',
  UI_COMPONENT_PROPERTY_COLUMNCOUNT_LABEL: 'Column Count',
  UI_COMPONENT_PROPERTY_SUPPLEMENTARYTEXT_LABEL: 'Supplementary Text',
  UI_COMPONENT_PROPERTY_COMPAREPRICE_LABEL: 'Comapare Price',
  UI_COMPONENT_PROPERTY_CURRENCY_LABEL: 'Currency',
  UI_COMPONENT_PROPERTY_PRICE_LABEL: 'Price',
  UI_COMPONENT_PROPERTY_VENDOR_LABEL: 'Vendor',
  UI_COMPONENT_PROPERTY_HEADINGSIZE_LABEL: 'Heading Size',
  UI_COMPONENT_PROPERTY_BUTTONINDICATOR_LABEL: 'Button Indicator',
  UI_COMPONENT_PROPERTY_SHOWMOBILE_LABEL: 'Show Mobile',
  UI_COMPONENT_PROPERTY_TYPE_LABEL: 'Type',
  UI_COMPONENT_PROPERTY_MEASUREMENTS_LABEL: 'Measurements',
  UI_COMPONENT_PROPERTY_TIMESTAMP_LABEL: 'Timestamp',
  UI_COMPONENT_PROPERTY_ACTIONINDICATOR_LABEL: 'Action Indicator',
  UI_COMPONENT_PROPERTY_ICONALIGN_LABEL: 'Icon Alignment',
  UI_COMPONENT_PROPERTY_DEFAULTSELECTEDTABID_LABEL: 'Default Selected Tab ID',
  UI_COMPONENT_PROPERTY_TABLIST_LABEL: 'Tab List',
  UI_COMPONENT_PROPERTY_TABLISTNAME_LABEL: 'Tab List Name',
  UI_COMPONENT_PROPERTY_IMAGEHEIGHT_LABEL: 'Image Height',
  UI_COMPONENT_PROPERTY_IMAGEALIGN_LABEL: 'Image Align',
  UI_COMPONENT_PROPERTY_AMOUNTPREFIX_LABEL: 'Amount Prefix',
  UI_COMPONENT_PROPERTY_BADGEBACKGROUNDCOLOR_LABEL: 'Badge Background Color',
  UI_COMPONENT_PROPERTY_VALUES_LABEL: 'Values',
  VALUES: 'Values',
  UI_COMPONENT_PROPERTY_XAXISLABEL_LABEL: 'X axis label',
  UI_COMPONENT_PROPERTY_INDEXVALUE_LABEL: 'Index Value',
  UI_COMPONENT_PROPERTY_INDEXLABEL_LABEL: 'Index label',
  UI_COMPONENT_PROPERTY_DISPLAYVALUE_LABEL: 'Display Value',
  UI_COMPONENT_PROPERTY_BARCOLOR_LABEL: 'Bar color',
  UI_COMPONENT_PROPERTY_ITEMS_LABEL: 'Items',
  UI_COMPONENT_PROPERTY_VALUE_LABEL: 'Value',
  UI_COMPONENT_PROPERTY_ID_LABEL: 'Property id',
  UI_COMPONENT_PROPERTY_TITLE_LABEL: 'Property title',
  UI_COMPONENT_PROPERTY_INFO_LABEL: 'Info',
  UI_COMPONENT_PROPERTY_ACTIVE_LABEL: 'Active',
  UI_COMPONENT_PROPERTY_CURRENTPAGE_LABEL: 'Current page',
  UI_COMPONENT_PROPERTY_MAXITEMSPERPAGE_LABEL: 'Maximum items per page',
  UI_COMPONENT_PROPERTY_RELOADNODEIDS_LABEL: 'Reload node ids',
  UI_COMPONENT_PROPERTY_TOTALITEMS_LABEL: 'Total items',
  UI_COMPONENT_PROPERTY_TOTALPAGES_LABEL: 'Total pages',
  UI_COMPONENT_PROPERTY_ALTTEXT_LABEL: 'Alt text',
  UI_COMPONENT_PROPERTY_THUMBNAIL_LABEL: 'Thumbnail',
  RELOADNODEIDS: 'Reload node ids',
  NODE_SECTION_LABEL: 'Sections',
  NODE_SECTION_HINT:
    'Different component types can support one or more sections. This will allow you to define what appears in each of the sections available for this component type. If you want to add an item that doesn’t exist yet - you will need to create a new item first, and then add it here.',
  NODE_SECTION_ITEM_LABEL: 'Items',
  NODE_SECTION_ITEM_HINT:
    'Items are the children of the component type you selected. Here you decide which other components from an existing list you would like to add to this configuration. If you want to add an item that doesn’t exist yet - you will need to create a new item first, and then add it here.',
  ADD_NODE_ARRAY_LABEL: 'Add a Node',
  SELECTED_NODE_ID_LABEL: 'Id',
  SELECTED_NODE_LABEL: 'Node',
  NODE_ENTITY_SELECT_NODE: 'Select Node',
  NODE_STATE_ACTIONS_LABEL: 'Actions',
  NODE_STATE_ACTIONS_HINT:
    'Actions are how interactions are handled on this node. The type of action that can be configured depends on the component type you’ve chosen.',
  ONCLICK: 'On click',
  ONLOAD: 'On load',
  ONPLAY: 'On play',
  ONPAUSE: 'On pause',
  ONDISMISS: 'On dismiss',
  ONBUTTONCLICKED: 'On button clicked',
  ONBUTTONCLICK: 'On button click',
  ONEND: 'On end',
  RELOAD_NODES: 'Reload Nodes',
  SHOW_HIDDEN_NODES: 'Show Hidden Nodes',
  TOGGLE_VISIBILITY: 'Toggle Visibility',
  'UN-DISMISS': 'Un Dismiss',
  METRIC_CARD_DISCLAIMER_MODAL: 'Metric Card Disclaimer Modal',
  HIDE_NODES: 'Hide Nodes',
  DISMISS: 'Dismiss',
  DEEPLINK: 'Deeplink',
  NODE_STATE_SUPPORTED_ACTIONS: 'Actions',
  ACTION_STATE_COMPONENT_TYPE_LABEL: 'Set an action type',
  ACTION_STATE_COMPONENT_TYPE_HINT:
    'Choose the type of action for this action slot',
  ACTION_STATE_SELECT_ACTION_STATE: 'Select action type',
  ACTION_NODEIDS_LABEL: 'Node ID',
  ACTION_URL_LABEL: 'Url',
  ACTION_BODY_LABEL: 'Body',
  ACTION_BUTTONACTION_LABEL: 'Button Action',
  ACTION_BUTTONLABEL_LABEL: 'Button Label',
  ACTION_HEADING_LABEL: 'Heading',
  RECOMMENDEDCONTENTCARD: 'Recommended Content Card',
  STATUS_BANNER: 'Status Banner',
  BUTTON: 'Button',
  AUTHORIZATION_SERVICE_INFO_SECTION: 'Authorization service info section',
  TEXT_ACTION: 'Text Action',
  AUTHORIZATION_SERVICE_CARD: 'Authorization service card',
  AUTHORIZATION_DETAILS_HEADER: 'Authorization details header',
  AUTHORIZATION_CARD: 'Authorization card',
  AUTHORIZATIONS_EMPTY_STATE: 'Authorization empty state',
  RICH_TEXT: 'Rich Text',
  ONLOAD_ANALYTICS: 'Onload analytics',
  DEFAULT_STATE_SUPPORTED_ACTIONS: 'Supported Actions',
  ONHEADERBUTTONCLICK: 'On header button click',
  ONFOOTERBUTTONCLICK: 'On footer button click',
  ONSCROLLLEFT: 'On scroll left',
  ONSCROLLRIGHT: 'On scroll right',
  NODE_RESOLVERS: 'Resolvers',
  NODE_RESOLVER: 'Resolver',
  NODE_DEFAULT_STATE_RESOLVER: 'Resolver',
  NODE_RESOLVER_ALIAS_LABEL: 'Alias',
  NODE_DEFAULT_STATE_ACTION_RESOLVER: 'Resolver',
  NODE_DEFAULT_STATE_ACTION_RESOLVER_LABEL: 'Resolvers',
  NODE_DEFAULT_STATE_ACTION_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  ANALYTICS_EVENT_NAME_LABEL: 'Event Name',
  ANALYTICS_SCREEN_NAME_LABEL: 'Screen Name',
  ANALYTICS_PRODUCT_AREA_LABEL: 'Product Area',
  ANALYTICS_SUB_PRODUCT_AREA_LABEL: 'Sub Product Area',
  ANALYTICS_DETAIL_LABEL: 'Detail',
  ACTIONS_ANALYTICS: 'Analytics',
  NODE_LOADING_STATE_RESOLVER_LABEL: 'Resolvers',
  NODE_ERROR_STATE_RESOLVER_LABEL: 'Resolvers',
  NODE_LOADING_STATE_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  NODE_ERROR_STATE_RESOLVER_HINT:
    'Select a resolver that contains the data points you want to use for filtering. You can select multiple resolvers if the data you need is across different ones.',
  NODE_ERROR_STATE_RESOLVER: 'Resolver',
  NODE_LOADING_STATE_RESOLVER: 'Resolver',
  UI_COMPONENT_ERROR_STATE_PROPERTIES_LABEL: 'Error State Properties',
  UI_COMPONENT_ERROR_STATE_PROPERTIES_HINT:
    'To set the properties for the error state of this UI Component, you will first select which resolvers contain the content data you want, and then select the specific data points from that resolver you’d like to import. After that, you’re going to map it to the elements within this component that can accept content.',
  NODE_TYPE_PROPERTY_MAPPING_TYPE: 'Mapping Type',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_PLACEHOLDER:
    'Select the property mapping type',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_BANNER_TITLE: 'Note',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_BANNER_DESCRIPTION:
    'Please note: If you change the mapping type, you will need to start your mapping over again.',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_GJSON: 'Advanced mapping with GJSON',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_INPUT_VALUE: 'Manual mapping',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_GJSON_LABEL: 'Advanced mapping with GJSON',
  NODE_TYPE_PROPERTY_MAPPING_TYPE_GJSON_HINT:
    'This setting allows you to manually write in a mapping definition using GJSON',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE: 'Mapping Type',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_PLACEHOLDER:
    'Select the property mapping type',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_BANNER_TITLE: 'Note',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_BANNER_DESCRIPTION:
    'Please note: If you change the mapping type, you will need to start your mapping over again.',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_GJSON: 'Advanced mapping with GJSON',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_INPUT_VALUE: 'Manual mapping',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_GJSON_LABEL: 'Advanced mapping with GJSON',
  ACTION_TYPE_PAYLOAD_MAPPING_TYPE_GJSON_HINT:
    'This setting allows you to manually write in a mapping definition using GJSON',
};
