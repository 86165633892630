import { useMemo, useEffect, useCallback } from 'react';
import type {
  EntityRootData,
  EntityDetail,
  EntityDetailSchema,
  Entity,
  EntityStatus,
} from '@web-config-app/core';
import { EntityStatusValue, getEntityInstanceName } from '@web-config-app/core';
import { get, set } from 'lodash-es';
import { useEntityTree } from '../use-entity-tree/use-entity-tree';
import { useEntityTreeState } from '../use-entity-tree-state/use-entity-tree-state';
import type { TreeNode } from '../../types/tree';

export interface UseEntityDetailsProps {
  rootSchema: EntityDetailSchema;
  setEntityRootData: (data: EntityDetail) => void;
  entity: Entity;
  entityRootData: EntityRootData | undefined;
  path?: string;
  instanceId?: string;
  onTreeNodeSelect?: (args: any) => void;
}

const removeJsonPathPrefix = (namePath: string) =>
  namePath.indexOf('$.') === 0 ? namePath.substring(2) : namePath;

export type UseEntityDetailsResult = ReturnType<typeof useEntityDetails>;

const rootPaths = ['root', ''];
const getFullEntityDataPath = (path: string) =>
  rootPaths.includes(path) ? 'attributes' : `attributes.${path}`;

export const useEntityDetails = ({
  rootSchema,
  entityRootData,
  setEntityRootData,
  path,
  instanceId,
  onTreeNodeSelect,
  entity,
}: UseEntityDetailsProps) => {
  const {
    expandedNodes,
    toggleNode,
    expandParentNodesForPath,
    resetNodes: resetEntityTreeNodes,
  } = useEntityTreeState((state) => state);

  const { entityTree, nodeMap, handleNodeToggle } = useEntityTree({
    schema: rootSchema,
    data: entityRootData,
    onSelect: (node: TreeNode) => {
      const nodeId = typeof node === 'string' ? node : node?.id;
      onTreeNodeSelect?.(node);
      if (typeof nodeId === 'string') {
        toggleNode(nodeId);
      }
    },
  });

  const { formSchema, formData, formPath } = useMemo(() => {
    if (path) {
      const currentNode = nodeMap.get(path);
      if (currentNode && currentNode.data) {
        const {
          data: { schema: schemaForPath },
        } = currentNode;
        const dataPath = getFullEntityDataPath(path);
        const dataForPath = get(entityRootData, dataPath);
        return {
          formSchema: schemaForPath,
          formData: dataForPath,
          formPath: path,
        };
      }
    }

    return {
      formSchema: undefined,
      formData: undefined,
      formPath: undefined,
    };
  }, [entityRootData, nodeMap, path]);

  useEffect(() => {
    /**
     * Ensure that the node for the current path as well as any
     * parent nodes are expanded. This may be necessary in cases where
     * a form is opened via deep link or object card
     */
    if (formPath) {
      expandParentNodesForPath(formPath);
    }
  }, [formPath, expandParentNodesForPath]);
  /**
   * pass this function to the EntityForm so that as its data changes, it can update the `rootData` accordingly.
   */
  const onFormDataChange = useCallback(
    (updatedFormData: any) => {
      const dataPath = getFullEntityDataPath(path ?? '');
      const newData = set(
        { ...entityRootData },
        dataPath,
        updatedFormData,
      ) as EntityDetail;
      setEntityRootData(newData);
    },
    [path, entityRootData, setEntityRootData],
  );

  /**
   * Callback to set an entity's name attribute directly.
   *
   * NOTE: this is currently only implemented in the config app for
   * entities that do not use a custom name path but the function
   * allows for those entities to also use the API in the future if
   * needed.
   */
  const setEntityName = useCallback(
    (name: string) => {
      const customNamePath = rootSchema['x-entity-metadata']?.entityNamePath;
      const namePath = customNamePath
        ? removeJsonPathPrefix(customNamePath)
        : 'attributes.entityMetadata.name';

      if (entityRootData) {
        const dataWithName = set(
          { ...entityRootData },
          namePath,
          name,
        ) as EntityDetail;

        setEntityRootData(dataWithName);
      }
    },
    [rootSchema, entityRootData, setEntityRootData],
  );

  const isReadOnly = entityRootData?.attributes?.entityMetadata?.readOnly;

  return {
    name: getEntityInstanceName(entityRootData, entity),
    status:
      entityRootData?.attributes?.entityMetadata?.status ??
      (EntityStatusValue.New as EntityStatus),
    instanceId,
    entityTree,
    nodeMap,
    expandedNodes,
    handleNodeToggle,
    resetEntityTreeNodes,
    rootSchema,
    formSchema,
    formData,
    formPath,
    onFormDataChange,
    setEntityName,
    isReadOnly,
  };
};
