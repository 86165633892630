import jp from 'jsonpath';
import type { EntityRootData, Entity } from '../types/entities';
import type { EntityConfig } from '../types/configuration';
import { isEntity } from './is-entity/is-entity';

/**
 * Retrieves the instance name of an entity from the provided data.
 *
 * @param data - The root data containing entity information. It can be undefined.
 * @param entity - The entity or entity configuration object.
 * @returns The instance name of the entity if found, otherwise null.
 */

export const getEntityInstanceName = (
  data: EntityRootData | undefined,
  entity: Entity | EntityConfig,
) => {
  const interfaceEntityName = data?.attributes?.entityMetadata?.name;
  if (interfaceEntityName) {
    return interfaceEntityName;
  }

  if (!isEntity(entity) || typeof data !== 'object') {
    return null;
  }

  const customEntityNamePath =
    entity.schema['x-entity-metadata']?.entityNamePath;

  const instanceName = customEntityNamePath
    ? data && jp.value(data, customEntityNamePath)
    : null;

  return instanceName;
};
