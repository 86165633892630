import { CreateTenantConfigInfo } from '@web-config-app/core';
import { AppTenant, TenantId } from 'types';
import { highmarkIELConfig } from './highmark-iel/highmark-iel.config';
import { manulifeConfig } from './manulife/manulife.config';

/**
 * @description `tenantConfigs` is an object containing optional configurations. Not every tenant
 * will have a need to implement these options. See {@link CreateTenantConfigInfo} for more details.
 */

export const optionalConfigInfoByTenant: Partial<
  Record<TenantId, CreateTenantConfigInfo>
> = {
  [AppTenant.HighmarkIel]: highmarkIELConfig,
  [AppTenant.Manulife]: manulifeConfig,
};
