import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { useQueryClient } from '@tanstack/react-query';
import {
  useEntity,
  useConfigAppParams,
  useConfigAppPath,
  useSearchParam,
  useConfigUserFeedback,
  useEntityOperationError,
  useAuthoringEnvironments,
  useAuthoringEnvironmentInfo,
} from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';
import {
  useEntityDetailsProps,
  DataSourceStoreProvider,
} from '@web-config-app/entity-form';
import {
  EntityDetailsPageContainer,
  EntityDetailsPage,
} from '@web-config-app/core-react-containers';
import type {
  EntityDetail,
  Entity,
  EnvironmentKey,
} from '@web-config-app/core';
import { entityDetailActionPendingKey } from '@web-config-app/core';
import { openInTab } from '@leagueplatform/web-common';

interface EntityDetailsPageWithContextProps {
  instanceId?: string;
  entity: Entity;
  entityListPath: string;
  environmentKey: EnvironmentKey;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
}

const EntityDetailsPageWithContext = ({
  instanceId,
  entity,
  entityListPath,
  environmentKey,
  getEntityDetailsPathForEnvAndId,
}: EntityDetailsPageWithContextProps) => {
  const history = useHistory();
  const {
    status,
    formData,
    formSchema,
    formPath,
    entityRootData,
    onFormDataChange,
    handleNodeToggle,
    entityTree,
    operations,
    expandedNodes,
    resetEntityTreeNodes,
    setEntityName,
    currentAuthoringEnvironment,
    isReadOnly,
  } = useEntityDetailsProps();
  const { formatMessage } = useIntl();
  const { publishDangerously, nameKey } = useAuthoringEnvironmentInfo(
    currentAuthoringEnvironment,
  );

  const environmentName = formatMessage({ id: nameKey });
  /**
   * This will reset the entity tree expanded state store whenever we load a new entity instance.
   */
  React.useEffect(() => {
    resetEntityTreeNodes();
  }, [instanceId, resetEntityTreeNodes]);

  React.useEffect(() => {
    /**
     * When landing on the route initially we want to set the first entity tree
     * node's id as url param in order to open and mark the first node as active
     */
    const isInstanceDataLoaded = Boolean(instanceId && operations.get.data);
    if (
      (!formPath || formPath === '') &&
      (isInstanceDataLoaded || !instanceId)
    ) {
      const [firstNode] = entityTree;
      const searchParams = new URLSearchParams({ path: firstNode.id });
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [formPath, entityTree, history, instanceId, operations.get.data]);

  const { isError, isLoading } = operations.get;

  return (
    <EntityDetailsPage
      entity={entity}
      entityData={entityRootData}
      entityListPath={entityListPath}
      isLoading={isLoading}
      isError={isError}
      formData={formData}
      formSchema={formSchema}
      formPath={formPath}
      entityTree={entityTree}
      handleNodeToggle={handleNodeToggle}
      onFormDataChange={onFormDataChange}
      expandedNodes={expandedNodes}
      publishDangerously={publishDangerously}
      entityStatus={status}
      environmentName={environmentName}
      environmentKey={environmentKey}
      isReadOnly={isReadOnly}
      getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
      setEntityName={setEntityName}
    />
  );
};

interface EntityDetailsTestProps {
  /**
   * The __testOptions prop was added to make it possible to mock leagueFetch. It allows the test cases
   * to pass in pretty much whatever's needed. Though, for now, it's only the `fetchFn` prop being
   * passed to the `useEntityOperations` hooks.
   *
   * This was added to mitigate an issue mocking a dependency of a dependency (in this case, `leagueFetch` being
   * a dependency of `@web-config-app/core-react` -> `web-config-app/api` -> `@leagueplatform/league-fetch`) which
   * has proven difficult, even following some github issue discussions. See:
   *
   * https://github.com/vitest-dev/vitest/issues/1336
   * https://github.com/vitest-dev/vitest/issues/3936
   *
   * This prop should not be used in the production app.
   */
  _testOptions?: any;
}

export const EntityDetails = ({ _testOptions }: EntityDetailsTestProps) => {
  const { formatMessage } = useIntl();
  const { domainId, entityId, entityInstanceId, environmentKey } =
    useConfigAppParams<{
      domainId: string;
      entityId: string;
      entityInstanceId: string;
      environmentKey: EnvironmentKey;
    }>();

  const generateConfigPaths = useConfigAppPath();
  const {
    getEntityDetailsPathForId,
    entityListPath,
    getEntityDetailsPathForEnvAndId,
  } = generateConfigPaths({
    domainId,
    entityId,
    environmentKey,
  });

  const history = useHistory();
  const queryClient = useQueryClient();

  const entity = useEntity({ domainId, entityId });
  const { otherAuthoringEnvironment, currentAuthoringEnvironment } =
    useAuthoringEnvironments({
      environmentKey,
    });

  const path = useSearchParam('path') ?? '';

  const hasEntityId = Boolean(entityInstanceId);

  const { resetConfigUserFeedbackStore, setEntityOperationLoadingMsg } =
    useConfigUserFeedback((state) => ({
      resetConfigUserFeedbackStore: state.resetConfigUserFeedbackStore,
      setEntityOperationLoadingMsg: state.setEntityOperationLoadingMsg,
    }));

  const handleEntityOperationError = useEntityOperationError();

  const entityName =
    entity && formatMessage({ id: entity.nameTranslationKey }, { count: 1 });

  /**
   *Reset on unmount of the page
   */
  React.useEffect(
    () => () => {
      resetConfigUserFeedbackStore();
    },
    [resetConfigUserFeedbackStore],
  );

  return entity?.schema ? (
    <DataSourceStoreProvider>
      <EntityDetailsPageContainer
        entity={entity}
        instanceId={entityInstanceId}
        currentAuthoringEnvironment={currentAuthoringEnvironment}
        path={path}
        createDefaultEntityData={!hasEntityId}
        otherAuthoringEnvironment={otherAuthoringEnvironment}
        options={{
          get: {
            retry: 1,
            ..._testOptions?.get,
          },
          update: {
            onMutate: (operation) => {
              const loadingMessage = formatMessage(
                { id: entityDetailActionPendingKey[operation] },
                { entity: entityName },
              );
              setEntityOperationLoadingMsg(loadingMessage);
            },
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [environmentKey, entity.endpoints.list.path],
              });
              queryClient.invalidateQueries({
                queryKey: [
                  environmentKey,
                  entity.endpoints.get.path,
                  entityInstanceId,
                ],
              });
              resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            onSettled: () => {
              setEntityOperationLoadingMsg(null);
            },
            ..._testOptions?.update,
          },
          create: {
            onMutate: (operation) => {
              const loadingMessage = formatMessage(
                { id: entityDetailActionPendingKey[operation] },
                { entity: entityName },
              );
              setEntityOperationLoadingMsg(loadingMessage);
            },
            onSuccess: ({ data }: { data: EntityDetail }) => {
              queryClient.invalidateQueries({
                queryKey: [environmentKey, entity.endpoints.list.path],
              });
              const newEntityId = data?.id;
              history.push(getEntityDetailsPathForId(newEntityId));
              resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            onSettled: () => {
              setEntityOperationLoadingMsg(null);
            },
            ..._testOptions?.create,
          },
          duplicateAsDraft: {
            onMutate: (operation) => {
              const loadingMessage = formatMessage(
                { id: entityDetailActionPendingKey[operation] },
                { entity: entityName },
              );
              setEntityOperationLoadingMsg(loadingMessage);
            },
            onSuccess: ({ data }: { data: EntityDetail }) => {
              queryClient.invalidateQueries({
                queryKey: [entity.endpoints.list.path],
              });

              const newEntityId = data?.id;
              openInTab(getEntityDetailsPathForId(newEntityId));
              resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            onSettled: () => {
              setEntityOperationLoadingMsg(null);
            },
            ..._testOptions?.duplicateAsDraft,
          },
        }}
        multiEnvironmentOptions={{
          copyToEnvironment: {
            onMutate: (operation, environment) => {
              const loadingMessage = formatMessage(
                { id: entityDetailActionPendingKey[operation] },
                { entity: entityName, environment },
              );
              setEntityOperationLoadingMsg(loadingMessage);
            },
            onSuccess: (
              { data }: { data: EntityDetail },
              environment: EnvironmentKey,
            ) => {
              queryClient.invalidateQueries({
                queryKey: [
                  environmentKey,
                  entity.endpoints.get.path,
                  entityInstanceId,
                ],
              });
              resetConfigUserFeedbackStore();
              const newEntityId = data?.id;
              history.push(
                getEntityDetailsPathForEnvAndId(newEntityId, environment),
              );
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            onSettled: () => {
              setEntityOperationLoadingMsg(null);
            },
            ..._testOptions?.copyToEnvironment,
          },
        }}
      >
        <EntityDetailsPageWithContext
          entity={entity}
          instanceId={entityInstanceId}
          entityListPath={entityListPath}
          environmentKey={environmentKey}
          getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
        />
      </EntityDetailsPageContainer>
    </DataSourceStoreProvider>
  ) : null;
};
