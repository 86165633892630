import { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import type {
  EnvironmentKey,
  EntityDetail,
  EntityDetailSchema,
} from '@web-config-app/core';
import type { CopyAssetToEnvironmentResult } from '../../types/operation.types';
import { useFindEntityAssetsOnEnvironment } from '../use-find-entity-assets-on-environment/use-find-entity-assets-on-environment';
import { useCopyEntityAssetsToEnvironment } from '../use-copy-entity-assets-to-environment/use-copy-entity-assets-to-environment';
import { useConfigUserFeedback } from '../../state/config-user-feedback-state/config-user-feedback-state';
import { getEntityAssetReferenceIds } from '../../utilities/get-entity-asset-reference-ids/get-entity-asset-reference-ids';

export const extractMissingAssetIds = (
  results: CopyAssetToEnvironmentResult[],
) => results.filter(({ status }) => status === 'error').map(({ id }) => id);

export interface UseSyncReferencedAssetEntitiesProps {
  /**
   * Schema of the entity to check for asset references
   */
  schema: EntityDetailSchema;
  /**
   * The source environment is equal to the current environment, we consider it
   * the source for all assets that need to be copied
   */
  sourceEnvironment: EnvironmentKey;
  /**
   * The target environment is the other environment on which we need to check
   * for assets and then copy them if they are missing
   */
  targetEnvironment: EnvironmentKey | undefined;
}

export type UseSyncReferencedAssetEntitiesResult = ReturnType<
  typeof useSyncReferencedAssetEntities
>;

/**
 * Finds all asset references in the entity data, checks their status on the target environment, and
 * finally copies any missing assets from the source environment to the target environment.
 */

export const useSyncReferencedAssetEntities = ({
  schema: entitySchema,
  sourceEnvironment,
  targetEnvironment,
}: UseSyncReferencedAssetEntitiesProps) => {
  if (!targetEnvironment) {
    throw new Error(
      'useSyncReferencedAssetEntities: Target environment id is required, got undefined',
    );
  }
  const { formatMessage } = useIntl();
  const findAssetsOnTargetEnvironment = useFindEntityAssetsOnEnvironment({
    environment: targetEnvironment,
  });

  const copyEntityAssetsToEnvironment = useCopyEntityAssetsToEnvironment({
    sourceEnvironment,
    targetEnvironment,
  });

  const setEntityOperationLoadingMsg = useConfigUserFeedback(
    (state) => state.setEntityOperationLoadingMsg,
  );

  return useCallback(
    async (entityData?: Partial<EntityDetail> | undefined) => {
      const entityAssetReferenceIds: string[] = getEntityAssetReferenceIds(
        entitySchema,
        entityData,
      );
      /**
       * If the entity has no set asset references, return a success status
       */
      if (entityAssetReferenceIds.length === 0) {
        return { status: 'success', results: [] };
      }

      setEntityOperationLoadingMsg(
        formatMessage(
          {
            id: 'SYNCING_ASSETS_WITH_ENVIRONMENT_ACTION',
          },
          {
            environment: targetEnvironment,
            assetCount: entityAssetReferenceIds.length,
          },
        ),
      );

      const findOnTargetResults = await findAssetsOnTargetEnvironment(
        entityAssetReferenceIds,
      );
      const missingAssetIds = extractMissingAssetIds(findOnTargetResults);

      /**
       * If all the assets are already on the target environment, return a success status
       */
      if (missingAssetIds.length === 0) {
        return { status: 'success', results: [] };
      }

      const copyResults = await copyEntityAssetsToEnvironment(missingAssetIds);

      /**
       * Assign a status to the overall operation: if every missing asset was successfully
       * copied then we send `success`. If _any_ of the assets failed to copy, we send `error
       */
      const status = copyResults.every((result) => result.status === 'success')
        ? 'success'
        : 'error';

      return {
        status,
        results: copyResults,
      };
    },
    [
      setEntityOperationLoadingMsg,
      formatMessage,
      targetEnvironment,
      copyEntityAssetsToEnvironment,
      findAssetsOnTargetEnvironment,
      entitySchema,
    ],
  );
};
