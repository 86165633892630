import { useMemo } from 'react';
import type { EntityDetail } from '@web-config-app/core';

const parseEntityData = (
  data: any,
  parentPath = '',
): { path: string; data: any }[] => {
  let result: { path: string; data: any }[] = [];

  Object.keys(data ?? {}).forEach((key) => {
    const currentPath = parentPath ? `${parentPath}.${key}` : key;
    if (typeof data[key] === 'object' && data[key] !== null) {
      result = result.concat(parseEntityData(data[key], currentPath));
    } else {
      const rowData = typeof data[key] !== 'object' ? `${data[key]}` : '';
      result.push({ path: currentPath, data: rowData });
    }
  });

  return result;
};

export const useFormatEntityTableData = (
  data: Partial<EntityDetail> | undefined,
) => {
  const dataToParse = data?.attributes;
  return useMemo(() => parseEntityData(dataToParse), [dataToParse]);
};
