import type { EnvironmentKey } from '@web-config-app/core';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';

export const useAuthoringEnvironmentInfo = (environment?: EnvironmentKey) => {
  const {
    environmentMap,
    baseAuthoringEnvironment,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments();

  if (!environment || !multiEnvironmentAuthoringEnabled) {
    return baseAuthoringEnvironment;
  }

  const foundEnvironment = environmentMap?.get(environment);

  if (!foundEnvironment) {
    throw new Error(`Unknown environment: ${environment}`);
  }

  return foundEnvironment;
};
