import type { EntityDetailSchema } from '@web-config-app/core';
import { useQuery } from '@tanstack/react-query';

interface UseEntityProps {
  entityId: string | undefined;
  schemaPath?: string;
}

export const useEntitySchema = ({
  entityId,
  schemaPath = '/schemas',
}: UseEntityProps) => {
  const {
    data: schema,
    isError,
    error,
  } = useQuery<EntityDetailSchema>({
    queryKey: ['schemas', entityId],
    queryFn: async () => {
      const response = await fetch(`${schemaPath}/${entityId}.json`);
      return response.json();
    },
    retry: false,
    enabled: !!entityId,
  });

  if (isError) {
    throw new Error(`Could not load schema for entity ${entityId}`, error);
  }

  return schema;
};
