import React from 'react';
import { Button } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { getEntityInstanceName } from '@web-config-app/core';
import type {
  Entity,
  EntityDetail,
  ListEndpointPaginationLinks,
  EnvironmentKey,
  Nullable,
} from '@web-config-app/core';
import type {
  GetEntityDetailsPathForId,
  AvailableEnvironment,
} from '@web-config-app/core-react';
import { EntityListPageLayout } from '../entity-list-page-layout/entity-list-page-layout.component';
import { EmptyStateCard } from '../empty-state-card/empty-state-card.component';
import { LinkableEntityListTable } from '../entity-list-table/linkable-entity-list-table/linkable-entity-list-table.component';
import { EntityListTablePagination } from '../entity-list-table/entity-list-table-pagination/entity-list-table-pagination.component';

export type EntityListPageViewProps = {
  entity: Entity;
  displayEmptyCard: boolean;
  emptyCardHeading: string;
  emptyCardDescription: string;
  entityCreatePath: string;
  getEntityDetailsPathForId: GetEntityDetailsPathForId;
  data: EntityDetail[] | undefined;
  isLoading: boolean;
  pagination: ListEndpointPaginationLinks | undefined;
  availableEnvironments: Nullable<AvailableEnvironment[]>;
  currentAuthoringEnvironment: EnvironmentKey;
  multiEnvironmentAuthoringEnabled: boolean;
  loadPage: (page: string) => void;
};

export const EntityListPageView = ({
  displayEmptyCard,
  emptyCardHeading,
  emptyCardDescription,
  entity,
  entityCreatePath,
  getEntityDetailsPathForId,
  currentAuthoringEnvironment,
  multiEnvironmentAuthoringEnabled,
  data,
  isLoading,
  pagination,
  loadPage,
  availableEnvironments,
}: EntityListPageViewProps) => {
  const { formatMessage } = useIntl();

  return (
    <EntityListPageLayout
      entityName={formatMessage(
        { id: entity.nameTranslationKey },
        { count: 2 },
      )}
      availableEnvironments={availableEnvironments}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
      actions={
        <Button
          as={Link}
          to={entityCreatePath}
          size="medium"
          icon="interfacePlus"
        >
          {formatMessage({ id: 'CREATE' })}
        </Button>
      }
    >
      {displayEmptyCard ? (
        <EmptyStateCard
          heading={emptyCardHeading}
          body={emptyCardDescription}
        />
      ) : (
        <>
          <LinkableEntityListTable
            getItemName={(d: EntityDetail) => getEntityInstanceName(d, entity)}
            getItemLinkUrl={getEntityDetailsPathForId}
            entityData={data}
            isLoading={isLoading}
          />
          {pagination && (
            <EntityListTablePagination
              pagination={pagination}
              loadPage={loadPage}
            />
          )}
        </>
      )}
    </EntityListPageLayout>
  );
};
