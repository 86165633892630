import {
  EnvironmentRegistryEntry,
  TenantAppEnvironment,
  TenantGeneratedSchemaEntry,
} from '@web-config-app/core';
import {
  ConfigTenantId,
  GeneratedConfigEnvironmentKey,
  GeneratedConfigTenantId,
} from '../../types';
import GeneratedTenantConfigs from './json/tenants.json';
import GeneratedEnvironmentConfigs from './json/environments.json';

/**
 *  @returns the environment object for the given env key from `./generated/environments.json`
 */

function getGeneratedEnvironmentConfig(
  tenantEnvironment: TenantAppEnvironment,
) {
  const baseEnvironmentConfig = GeneratedEnvironmentConfigs[
    tenantEnvironment.baseEnvironment.id as GeneratedConfigEnvironmentKey
  ] as EnvironmentRegistryEntry;

  const previewEnvironmentConfig = tenantEnvironment.previewEnvironment?.id
    ? (GeneratedEnvironmentConfigs[
        tenantEnvironment.previewEnvironment.id as GeneratedConfigEnvironmentKey
      ] as EnvironmentRegistryEntry)
    : undefined;

  const appEnvironment = {
    ...tenantEnvironment,
    auth0: {
      organizationId: tenantEnvironment.auth0.organizationId,
      clientId:
        tenantEnvironment.auth0.clientId ||
        baseEnvironmentConfig.auth0.clientId,
      domain:
        tenantEnvironment.auth0.domain || baseEnvironmentConfig.auth0.domain,
      audience: baseEnvironmentConfig.auth0.primaryAudience,
    },
    baseEnvironment: {
      ...baseEnvironmentConfig,
      id: tenantEnvironment.baseEnvironment.id,
      defaultAuthoringEnvironment:
        tenantEnvironment.baseEnvironment?.defaultAuthoringEnvironment,
      publishDangerously:
        tenantEnvironment.baseEnvironment.publishDangerously ||
        baseEnvironmentConfig.publishDangerously,
    },
    previewEnvironment: previewEnvironmentConfig && {
      ...previewEnvironmentConfig,
      id: tenantEnvironment.previewEnvironment?.id,
      defaultAuthoringEnvironment:
        tenantEnvironment.previewEnvironment?.defaultAuthoringEnvironment,
      publishDangerously:
        tenantEnvironment.previewEnvironment?.publishDangerously ||
        previewEnvironmentConfig.publishDangerously,
    },
  };
  return appEnvironment;
}

export function getGeneratedEnvironmentsConfigForTenant(
  tenantId: ConfigTenantId<GeneratedConfigTenantId>,
) {
  const tenantData: TenantGeneratedSchemaEntry = GeneratedTenantConfigs[
    tenantId
  ] as TenantGeneratedSchemaEntry;

  const tenantWithEnvironment = {
    ...tenantData,
    appEnvironment: {
      production: getGeneratedEnvironmentConfig(
        tenantData.appEnvironment.production,
      ),
      staging: getGeneratedEnvironmentConfig(tenantData.appEnvironment.staging),
    },
  };

  return tenantWithEnvironment;
}
