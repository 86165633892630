import { AnnotatedJsonSchema, SchemaTransformer } from '@web-config-app/core';
import jp from 'jsonpath';

/**
 * This function return a JSON schema equivalent of the type
 * returned by Masonry BE
 */
const formatType = (type: string) => {
  switch (type) {
    case 'string':
      return 'string';
    case 'int':
      return 'number';
    default:
      return 'string';
  }
};

/** This function format the properties into schema type so
 * platform can translate it into UI
 */
const getSchemaWithExtendedProperties = (
  schema: AnnotatedJsonSchema,
  extendedPropArray: any[],
) => {
  if (!Array.isArray(extendedPropArray)) return schema;

  const newSchema = { ...schema };
  const schemaOfProperties = extendedPropArray?.reduce((p: any, c: any) => {
    // eslint-disable-next-line no-param-reassign
    p[c.id] = {
      ...c,
      type: formatType(c.type),
      'x-entity-label': {
        key: c.name,
      },
    };
    if (c.required) {
      newSchema.required = newSchema.required
        ? [...newSchema.required, c.id]
        : [c.id];
    }
    return p;
  }, {});

  return {
    ...newSchema,
    properties: { ...newSchema.properties, ...schemaOfProperties },
  };
};

const isJsonPath = (path: string) => path.indexOf('$.') === 0;

const getDataFromPath = (data?: any, path?: string) => {
  if (!data || !path) return undefined;
  if (typeof data === 'object' && typeof path === 'string') {
    return isJsonPath(path) ? jp.value(data, path) : data[path];
  }
  return undefined;
};

export const injectDatasourceFields: SchemaTransformer = ({
  schema,
  data,
  options,
}) => {
  /** Only running this transformer if the array schema has x-entity-data-source and sourcePropertyPath is defined */
  if (
    !schema['x-entity-data-source'] ||
    !schema['x-entity-data-source'].sourcePropertyPath
  )
    return schema;

  const dataPropertySourceName =
    data && data[schema['x-entity-data-source'].sourcePropertyPath];

  if (dataPropertySourceName) {
    const dataSourceValue = options?.getInjectedEntityData?.(
      dataPropertySourceName,
    );
    const datasourcePropertyPath =
      schema['x-entity-data-source']?.schemaReferenceProperty;
    const dataFromDatasourcePropertyPath = getDataFromPath(
      dataSourceValue,
      datasourcePropertyPath,
    );
    const result = getSchemaWithExtendedProperties(
      schema,
      dataFromDatasourcePropertyPath,
    );
    return result;
  }
  return schema;
};
