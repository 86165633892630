import { useEffect } from 'react';
import type { EntityDetail } from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import type { EntityReferenceControlSchema } from '../../types/controls';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { useRelationship } from '../use-relationship/use-relationship';
import { useRelationshipsData } from '../use-relationships-data/use-relationships-data';
import type { UseEntityFormHandleChange } from '../use-entity-form-handle-change/use-entity-form-handle-change';

interface UseEntityReferenceSchemaProps {
  schema: EntityReferenceControlSchema;
  handleEntityFormChange?: UseEntityFormHandleChange;
}

export const useEntityReferenceSchema = ({
  schema,
  handleEntityFormChange,
}: UseEntityReferenceSchemaProps) => {
  const { relationship, ...entityReferenceAnnotation } =
    schema['x-entity-reference'];

  const {
    entityRootData,
    rootSchema,
    setEntityRootData,
    includedEntities,
    setIncludedEntities,
    addIncludedEntity,
  } = useEntityDetailsProps();

  const { type: entityType } = getRelationshipSchemaItemType(
    relationship,
    rootSchema,
  );

  /**
   * It looks like `useRelationshipsData` should be moved inside of `useRelationship`.
   * which shouldn't be too bad - just need to also include rootData and onRelationshipChange
   */

  const { getRelationship, setRelationships } = useRelationshipsData({
    schema: rootSchema,
    rootData: entityRootData,
    onRelationshipChange: (data) => {
      const { relationships, ...rootData } = entityRootData as EntityDetail;
      if (data)
        setEntityRootData({
          ...rootData,
          relationships: data,
        });
    },
  });

  const { setRelationship, ...relationshipProps } = useRelationship({
    relationship,
    schema: rootSchema,
    getRelationship,
    setRelationships,
  });

  const handleRelationshipChange = (
    relationshipEntity: EntityDetail,
    previousValue?: EntityDetail,
    index?: number,
  ) => {
    setRelationship(relationshipEntity, previousValue, index);
    addIncludedEntity?.(relationshipEntity);
    handleEntityFormChange?.(relationshipEntity.id);
  };

  useEffect(() => {
    /**
     * When the relationship value changes, remove any entities from the includedEntities
     * array that corresponds to a removed relationship.
     */
    if (
      relationshipProps.value &&
      Array.isArray(includedEntities) &&
      setIncludedEntities
    ) {
      const filteredIncludedEntities = includedEntities.filter(
        (includedEntity: EntityDetail) => {
          if (includedEntity.type !== entityType) return true;
          if (Array.isArray(relationshipProps.value)) {
            return relationshipProps.value.find(
              (r) => r.id === includedEntity.id,
            );
          }
          return includedEntity.id === relationshipProps.value.id;
        },
      );
      if (filteredIncludedEntities.length !== includedEntities.length) {
        setIncludedEntities(filteredIncludedEntities);
      }
    }
  }, [
    relationshipProps.value,
    entityType,
    includedEntities,
    setIncludedEntities,
  ]);

  return {
    ...entityReferenceAnnotation,
    ...relationshipProps,
    handleRelationshipChange,
  };
};
