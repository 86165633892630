import { useCallback } from 'react';
import type { EnvironmentKey, GetEndpointResponse } from '@web-config-app/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEntityReference } from '../use-entity-reference/use-entity-reference';
import { useEntityCreate } from '../use-entity-create/use-entity-create';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getIdParam } from '../../utilities/get-id-param/get-id-param';
import { getCreateConfigAssetPayload } from './get-create-config-asset-payload';
import type { ConfigAssetData } from './get-create-config-asset-payload';

export interface UseCopyEntityAssetsToEnvironmentProps {
  sourceEnvironment: EnvironmentKey;
  targetEnvironment: EnvironmentKey;
  entityType?: string;
}

/**
 * Responsible for coordinating the copying of assets from one environment to another
 * @param props.sourceEnvironment - the environment from which to copy the assets
 * @param props.targetEnvironment - the environment onto which the assets will be copied
 * @returns an async function that takes an array of asset IDs to copy from source -> target
 */

export const useCopyEntityAssetsToEnvironment = ({
  sourceEnvironment,
  targetEnvironment,
  entityType = 'config-asset',
}: UseCopyEntityAssetsToEnvironmentProps) => {
  const assetEntity = useEntityReference({ entityType });
  const { mutateAsync: copyAsset } = useEntityCreate({
    entity: assetEntity,
    environment: targetEnvironment,
    operation: 'create',
  });

  const {
    endpoints: { get },
    apiUrl,
  } = assetEntity;

  const sourceEnvironmentFetch = useEnvironmentFetch(sourceEnvironment, apiUrl);

  const { endpointFetch: sourceEnvironmentEndpointFetch } =
    getEntityEndpoint<GetEndpointResponse>(get, sourceEnvironmentFetch);

  const queryClient = useQueryClient();

  return useCallback(
    async (assetIdsOnSourceEnvironment: string[]) => {
      const sourceAssets = await Promise.all(
        assetIdsOnSourceEnvironment.map(async (assetId) => {
          try {
            const { data } = await queryClient.fetchQuery({
              queryKey: [sourceEnvironment, get.path, assetId],
              queryFn: () =>
                sourceEnvironmentEndpointFetch({
                  params: getEndpointParamsWithValues([
                    getIdParam(get.pathParameters ?? [], get.path, assetId),
                  ]),
                }),
              staleTime: 0,
              retry: false,
            });

            return { data, id: assetId, status: 'success' };
          } catch (error) {
            return { id: assetId, error, status: 'error' };
          }
        }),
      );

      const copyResult = await Promise.all(
        sourceAssets.map(async (sourceAsset) => {
          const { data, id, error, status } = sourceAsset ?? {};

          if (data && status === 'success') {
            /**
             * Extract the image data from the source asset and create a new file object
             */
            const formData = await getCreateConfigAssetPayload(
              data as ConfigAssetData,
            );

            if (!formData || !formData.get('file'))
              return {
                id,
                error: new Error('Failed to create file object'),
                status: 'error',
              };

            try {
              const { data: copyRequestData } = await copyAsset(formData);
              return { id, data: copyRequestData, status: 'success' };
            } catch (copyRequestError) {
              return { id, error: copyRequestError, status: 'error' };
            }
          }

          // pass on the error message and status for this asset from the GET call on the source environment
          return { id, error, status: 'error' };
        }),
      );

      queryClient.invalidateQueries({
        queryKey: [targetEnvironment, get.path],
      });

      return copyResult;
    },
    [
      get.path,
      get.pathParameters,
      targetEnvironment,
      sourceEnvironment,
      sourceEnvironmentEndpointFetch,
      copyAsset,
      queryClient,
    ],
  );
};
