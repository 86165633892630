import type { AppTenantConfig } from '@web-config-app/core';

export const validateTenantConfig = (tenantConfig: AppTenantConfig) => {
  const {
    configApp: {
      authoring: { baseEnvironment, previewEnvironments },
    },
  } = tenantConfig;

  if (Array.isArray(previewEnvironments)) {
    const environmentIds: string[] = [baseEnvironment.id];
    const environmentKeys: string[] = [baseEnvironment.environmentKey];
    previewEnvironments.forEach(({ type, id, environmentKey }) => {
      if (type === 'base') {
        throw new Error(
          `Authoring Config: Invalid type 'base' found for preview environment.`,
        );
      }
      // @ts-expect-error since the configs are env specific it may be possible to have an unexpected value here
      if (environmentKey === 'production') {
        throw new Error(
          `Authoring Config: Invalid environmentKey 'production' found for preview environment.`,
        );
      }
      if (environmentIds.includes(id)) {
        throw new Error(
          `Authoring Config: Found duplicate authoring environments with using the '${id}' '${tenantConfig.configApp.tenantName}' id. Each environment needs a unique id.`,
        );
      }
      if (environmentKeys.includes(environmentKey)) {
        throw new Error(
          `Authoring Config: Found duplicate authoring environments with using the '${environmentKey}' environmentKey. Each environment needs a unique environmentKey.`,
        );
      }
      environmentIds.push(id);
      environmentKeys.push(environmentKey);
    });
  }
  return tenantConfig;
};
