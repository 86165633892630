import * as React from 'react';
import type { Entity } from '@web-config-app/core';
import { isEntity } from '@web-config-app/core';
import { useEntityReference } from '../../hooks/use-entity-reference/use-entity-reference';
import { useEntitySchema } from '../../hooks/use-entity-schema/use-entity-schema';

export interface EntityLoaderProps {
  entityType: string;
  render: (entity: Entity) => React.ReactNode;
}

/**
 * EntityLoader component is responsible for loading the entity schema asynchronously
 * via the `useEntitySchema` hook since the schema is stored separately from the entity configuration.
 *
 * @param {EntityLoaderProps} props - The properties for the EntityLoader component.
 * @param {string} props.entityType - The type of the entity to be loaded.
 * @param {(entity: Entity) => React.ReactNode} props.render - A render function that takes an entity and returns a React node.
 *
 * @returns {React.ReactNode | null} - Returns the rendered entity if it is valid, otherwise returns null.
 */

export const EntityLoader = ({
  entityType,
  render,
}: React.PropsWithChildren<EntityLoaderProps>) => {
  const entityConfig = useEntityReference({ entityType });
  const entitySchema = useEntitySchema({ entityId: entityConfig.id });

  const entity = {
    ...entityConfig,
    schema: entitySchema,
  };

  /**
   * Since the schema is loaded asynchronously, we need to wait to render contents until the schema has been loaded.
   */
  return isEntity(entity) ? <>{render(entity)}</> : null;
};
