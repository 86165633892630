import React from 'react';
import { EntityListPageView } from '@web-config-app/core-react-ui';
import { useEntity, useConfigAppParams } from '@web-config-app/core-react';
import { EntityListPageContainer } from '@web-config-app/core-react-containers';

export function EntityListPage() {
  const { domainId, entityId, environmentKey } = useConfigAppParams();
  const entity = useEntity({ domainId, entityId });
  const hasResources = !!entity && !!domainId && !!entityId && !!environmentKey;
  return hasResources ? (
    <EntityListPageContainer
      entityListPageContentsRenderer={EntityListPageView}
      entity={entity}
      domainId={domainId}
      entityId={entityId}
      environmentKey={environmentKey}
    />
  ) : null;
}
