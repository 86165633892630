import * as React from 'react';
import { BaseInput } from '@leagueplatform/genesis-core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';

export const TextareaControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({
      data,
      handleChange,
      path,
      schema,
      required,
      inputStatus,
      enabled,
    }) => {
      const { maxLength, minLength } = schema;
      return (
        <BaseInput
          as="textarea"
          value={data ?? ''}
          readOnly={!enabled}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          inputStatus={inputStatus}
          onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (evt.currentTarget.value.trim() === '') {
              handleChange(path, undefined);
            } else {
              handleChange(path, evt.currentTarget.value);
            }
          }}
          css={{
            backgroundColor: '$inputBackgroundDefault',
            position: 'relative',
            '&:after': {
              pointerEvents: 'none',
              content: '"⟷"',
              position: 'absolute',
              textAlign: 'center',
              bottom: '6px',
              right: '-2px',
              // setting the writingMode here so the arrow text in the "content" is vertical
              writingMode: 'vertical-rl',
            },
            '& textarea': {
              resize: 'vertical',
              '&::-webkit-resizer': {
                display: 'none',
              },
            },
          }}
          rows={4}
        />
      );
    }}
  />
);
