import * as React from 'react';
import type {
  Entity,
  EntityDetail,
  EntityDetailSchema,
  EnvironmentKey,
} from '@web-config-app/core';
import {
  useEntityOperations,
  useEntityDetailsData,
  useIncludedEntities,
} from '@web-config-app/core-react';
import type {
  UseEntityOperationsProps,
  UseMultiEnvironmentEntityOperationsProps,
} from '@web-config-app/core-react';
import {
  EntityDetailsProvider,
  createDefaultValueForSchema,
  useSyncDataSources,
} from '@web-config-app/entity-form';
import {
  applyDefaultSchemaAnnotations,
  computeSchema,
  applyConditionalLogic,
} from '@web-config-app/schema-utils';
import { EntityDetailsMultiEnvironmentContainer } from '../entity-details-multi-environment-container/entity-details-multi-environment.container';

export interface EntityDetailsPageContainerProps {
  entity: Entity;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  currentAuthoringEnvironment: EnvironmentKey;
  path: string;
  options?: UseEntityOperationsProps['options'];
  multiEnvironmentOptions?: UseMultiEnvironmentEntityOperationsProps['options'];
  createDefaultEntityData?: boolean;
  otherAuthoringEnvironment?: EnvironmentKey;
}

export const EntityDetailsPageContainer = ({
  entity,
  instanceId,
  currentAuthoringEnvironment,
  options,
  multiEnvironmentOptions,
  path,
  children,
  createDefaultEntityData,
  otherAuthoringEnvironment,
}: React.PropsWithChildren<EntityDetailsPageContainerProps>) => {
  /**
   * The base schema is the schema as it is stored in the entity config without
   * any conditional logic applied.
   */
  const baseSchema = React.useMemo(
    () => applyDefaultSchemaAnnotations(entity.schema),
    [entity.schema],
  );
  const defaultEntityData = React.useMemo(
    () =>
      createDefaultEntityData
        ? createDefaultValueForSchema<EntityDetail>(baseSchema)
        : undefined,
    [baseSchema, createDefaultEntityData],
  );

  const { entityRootData, setEntityRootData } = useEntityDetailsData({
    data: defaultEntityData,
  });

  /**
   * We apply conditional rendering to the baseSchema using the current
   * entity data which will return either the full baseSchema or a schema
   * with some properties removed in the event that the conditions for
   * including them are not met
   */
  const rootSchema = React.useMemo(() => {
    const computedAttributesSchema = computeSchema(
      baseSchema.properties.attributes,
      entityRootData?.attributes,
      [applyConditionalLogic],
      { recursive: false },
    );

    return {
      ...baseSchema,
      properties: {
        ...baseSchema.properties,
        attributes: computedAttributesSchema,
      },
    } as EntityDetailSchema;
  }, [baseSchema, entityRootData]);

  const included = useIncludedEntities();

  useSyncDataSources(rootSchema, entityRootData, included.includedEntities);

  const operations = useEntityOperations({
    entity,
    instanceId,
    options,
    entityRootData,
    onEntityGetSuccess: (data, includedEntities) => {
      setEntityRootData(data);
      included.setIncludedEntities(includedEntities);
    },
    environment: currentAuthoringEnvironment,
  });

  return otherAuthoringEnvironment ? (
    <EntityDetailsMultiEnvironmentContainer
      entity={entity}
      instanceId={instanceId}
      multiEnvironmentOptions={multiEnvironmentOptions}
      entityRootData={entityRootData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      otherAuthoringEnvironment={otherAuthoringEnvironment}
      setEntityRootData={setEntityRootData}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      included={included}
    >
      {children}
    </EntityDetailsMultiEnvironmentContainer>
  ) : (
    <EntityDetailsProvider
      entityRootData={entityRootData}
      setEntityRootData={setEntityRootData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
      included={included}
    >
      {children}
    </EntityDetailsProvider>
  );
};
