import { useMemo } from 'react';
import { EntityConfig } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

/**
 * Retrieves an entity configuration based on the passed type.
 */

export const useEntityReference = ({ entityType }: { entityType: string }) => {
  const { domains } = useConfigApp();

  if (!entityType) {
    throw new Error(`useEntityReference: entityType is required`);
  }

  const entityConfig = useMemo(() => {
    const allEntities =
      domains.reduce((entities: EntityConfig[], domain) => {
        const domainEntities = domain.entities.map((domainEntity) => ({
          ...domainEntity,
          domain: domain.id,
        }));
        return [...entities, ...domainEntities];
      }, []) ?? [];

    const entityFromType = allEntities.find(
      (domainEntity) => domainEntity?.type === entityType,
    );

    if (!entityFromType) {
      throw new Error(`Unknown entity type: ${entityType}`);
    }

    return entityFromType;
  }, [entityType, domains]);

  return entityConfig;
};
