interface MergedDataSourceValue {
  baseSource: {
    value: Array<{ [k: string]: any }> | undefined;
  };
  mergeSources: Array<{
    data: Array<{ [k: string]: any }> | undefined;
    identifier: string;
    baseIdentifier: string;
    valueField: string;
  }>;
}

export const isMergedDataSourceValue = (
  value: any,
): value is MergedDataSourceValue =>
  typeof value === 'object' && 'baseSource' in value && 'mergeSources' in value;

/**
 * Merges data from multiple sources into the base source.
 *
 * @param {MergedDataSourceValue} param0 - The merged data source value containing the base source and merge sources.
 * @param {Object} param0.baseSource - The base source object.
 * @param {Array<{[k: string]: any}> | undefined} baseSource.value - The array of base source items.
 * @param {Array<Object>} param0.mergeSources - The array of merge source objects.
 * @param {Array<{[k: string]: any}> | undefined} param0.mergeSources[].data - The array of merge source items.
 * @param {string} param0.mergeSources[].identifier - The identifier field in the merge source.
 * @param {string} param0.mergeSources[].baseIdentifier - The identifier field in the base source.
 * @param {string} param0.mergeSources[].valueField - The field to be merged from the merge source to the base source.
 * @returns {Array<{[k: string]: any}> | undefined} The merged array of items from the base source.
 * @throws {Error} If the base source or merge sources are not provided.
 */

export const getMergedDataSourceValue = ({
  baseSource,
  mergeSources,
}: MergedDataSourceValue) => {
  if (!baseSource || !mergeSources) {
    throw new Error('Data source is not a merged data source');
  }

  return baseSource.value?.reduce((mappedItems: Array<any>, item) => {
    const matchVals: Array<any> = [];
    mergeSources.forEach(({ data, identifier, baseIdentifier, valueField }) => {
      const matches = data?.filter(
        (sourceItem: any) => sourceItem[identifier] === item[baseIdentifier],
      );
      matches?.forEach((match) => {
        matchVals.push({
          ...item,
          [valueField]: match[valueField],
        });
      });
    });

    return [...mappedItems, ...matchVals];
  }, []);
};
