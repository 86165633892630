import { useCallback } from 'react';
import { Domain } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';
import { useConfigAppPath } from '../use-config-app-path/use-config-app-path';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';

export const useAppRedirectPaths = () => {
  const { domains } = useConfigApp();
  const generateConfigPaths = useConfigAppPath();
  const { defaultAuthoringEnvironment } = useAuthoringEnvironments();

  const landingRedirectPath = useCallback(() => {
    const firstDomainId = domains[0]?.id;
    const { domainPath } = generateConfigPaths({
      domainId: firstDomainId,
      environmentKey: defaultAuthoringEnvironment.environmentKey,
    });
    if (!firstDomainId)
      throw new Error(
        'No domains found! Please add a domain to your tenants config object.',
      );
    return domainPath;
  }, [domains, generateConfigPaths, defaultAuthoringEnvironment]);

  const domainRedirectPath = useCallback(
    (domain: Domain) => {
      const [firstEntityId] = Array.from(domain?.entities ?? [])[0];
      const { entityListPath } = generateConfigPaths({
        domainId: domain.id,
        entityId: firstEntityId,
        environmentKey: defaultAuthoringEnvironment.environmentKey,
      });

      /**
       * In practice `entities` should not ever be empty so throw an error if it is
       */
      if (!firstEntityId)
        throw new Error(
          `Domain ${domain?.id} does not have any entities. Please add in an entity.`,
        );

      return entityListPath;
    },
    [defaultAuthoringEnvironment, generateConfigPaths],
  );

  return {
    landingRedirectPath,
    domainRedirectPath,
  };
};
