import React, { FC, useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, StatusBadge } from '@leagueplatform/genesis-core';
import { getEntityStatusBadgeProps } from '@web-config-app/core';
import { SelectActionMenu } from '../select-action-menu/select-action-menu.component';
import { getEnvironmentSelectModalProps } from './get-environment-select-modal-props';
import {
  EnvironmentSelectProps,
  SelectMenuItemGroup,
} from '../../types/components.types';

/**
 * EnvironmentSelect component displays a dropdown menu to select an environment
 * and perform actions related to that environment. The currently selected environment will render as the SelectActionMenu trigger label.
 *
 * The component UI consists of two main parts:
 * 1. **Environment Selection:** This section displays a list of available environments.
 *    Each environment can be selected to switch the current environment context.
 *    The selected environment is visually highlighted.
 * 2. **Additional Actions:** This section (optional) displays a list of actions that can be *    performed related to the environments. These actions are not selectable and do not
 *    change the current environment. They are simply triggers for other functionalities.
 *
 * **Important:** This component does not manage its own state for the `currentEnvironmentId`.
 * The parent component is responsible for providing the `currentEnvironmentId` prop and updating it
 * when an environment is selected.
 *
 * @param {EnvironmentSelectProps} props - Component properties. See {@link EnvironmentSelectProps} for details.
 */

export const EnvironmentSelect: FC<EnvironmentSelectProps> = ({
  currentAuthoringEnvironment,
  entityName,
  availableEnvironments,
  additionalActions,
}) => {
  const { formatMessage } = useIntl();
  const precedingLabel = formatMessage({
    id: 'CURRENT_ENVIRONMENT_PRECEDING_LABEL',
  });

  /**
   * Find the current environment object from the availableEnvironments array.
   * If not found, create a default environment object with the given ID.
   * This should not happen but providing a back-up just in case.
   */
  const currentEnvironment = availableEnvironments.find(
    (env) => env.id === currentAuthoringEnvironment,
  ) || {
    id: currentAuthoringEnvironment,
    label: currentAuthoringEnvironment,
    status: undefined,
  };

  const { label: environmentLabel, status: environmentStatus } =
    currentEnvironment;

  const menuLabel = formatMessage(
    { id: 'ENVIRONMENT_SELECT' },
    {
      environmentName: environmentLabel,
      status: environmentStatus,
    },
  );

  const statusBadgeProps = getEntityStatusBadgeProps(environmentStatus);
  const statusBadge = {
    label: formatMessage({ id: statusBadgeProps?.labelKey }),
    status: statusBadgeProps?.genesisStatus,
  };

  /**
   * Define the menu groups for the SelectActionMenu.
   * The first group is the list of available environments you can select.
   */
  const menuGroups = useMemo(() => {
    const groups: SelectMenuItemGroup[] = [
      {
        menuItems: availableEnvironments.map((env) => {
          const { labelKey: statusLabelKey, genesisStatus } =
            getEntityStatusBadgeProps(env.status);

          // Render the environment label with a status badge if the status is available.
          const label = env.status ? (
            <>
              {env.label}
              <StatusBadge
                label={formatMessage({ id: statusLabelKey })}
                status={genesisStatus}
                showLight={false}
                css={{ marginLeft: '$one', flexGrow: 0 }}
              />
            </>
          ) : (
            env.label
          );

          return {
            id: env.id,
            label,
            action: env.action,
            selected: env.id === currentAuthoringEnvironment,
            confirmationModal: getEnvironmentSelectModalProps({
              entityName,
              formatMessage,
              showModal: env.showConfirmationModal || false,
            }),
          };
        }),
      },
    ];

    // Add additional actions to the menu if provided.
    if (additionalActions) {
      groups.push(
        ...additionalActions.map((actionGroup) => ({
          menuItems: actionGroup.map((action) => ({
            id: action.id,
            label: action.label,
            action: action.action,
            disabledTooltip: action.disabledTooltip,
          })),
        })),
      );
    }

    return groups;
  }, [
    availableEnvironments,
    additionalActions,
    formatMessage,
    currentAuthoringEnvironment,
    entityName,
  ]);

  // Conditionally render the trigger label with a status badge if the status is available.
  const triggerLabel = environmentStatus ? (
    <Box>
      {environmentLabel}
      <StatusBadge
        data-testid="env-status"
        status={statusBadge.status}
        label={statusBadge.label}
        css={{ marginLeft: '$half' }}
        showLight={false}
      />
    </Box>
  ) : (
    environmentLabel
  );

  return (
    <SelectActionMenu
      menuLabel={menuLabel}
      precedingLabel={precedingLabel}
      menuGroups={menuGroups}
      triggerLabel={triggerLabel}
    />
  );
};
