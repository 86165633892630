import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import type {
  EnvironmentKey,
  EndpointResponse,
  EntityConfig,
  EntityRootData,
  OperationType,
} from '@web-config-app/core';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import {
  OnMutateWithOperation,
  OnSuccessWithEnvironment,
} from '../../types/operation.types';
import { injectOperationEntityMetadataRequiredFields } from '../../utilities/inject-operation-entity-metadata-required-fields/inject-operation-entity-metadata-required-fields';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';

export type UseEntityCreateResult = ReturnType<typeof useEntityCreate>;

export type UseEntityCreateOptions = Pick<
  UseMutationOptions<EndpointResponse, Error, BodyInit | void>,
  'onSuccess' | 'onError' | 'onSettled'
> & {
  onMutate?: OnMutateWithOperation;
  fetchFn?: (path: string, options: any) => Promise<Response>;
  onSuccess?: OnSuccessWithEnvironment;
};

export interface UseEntityCreateProps {
  entity: EntityConfig;
  data?: EntityRootData;
  headers?: HeadersInit;
  environment: EnvironmentKey;
  options?: UseEntityCreateOptions;
  operation: OperationType;
}

/**
 * useEntityCreate - The following hook is responsible for calling the BE API associated to the Create (POST) service of an entity.
 * @param props
 * @param props.entity - the whole {@link Entity} that exposes the needed values including the id, endpoints, etc.
 * @param props.data - the root data of the form used when saving the entity data
 * @param props.headers - optional headers to pass into the fetch function
 * @param props.options - optional config object
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default currentEnvironmentFetch
 * @returns the result of useMutation, which is then used by our EntityDetailsProvider, who is responsible for relaying the result where needed.
 */
export const useEntityCreate = ({
  entity,
  data,
  headers,
  options,
  environment,
  operation,
}: UseEntityCreateProps) => {
  const {
    id: entityId,
    endpoints: { create: createOperation },
    apiUrl,
  } = entity;
  if (!createOperation) {
    throw new Error(`Entity ${entityId} has no defined create endpoint`);
  }
  const environmentFetch = useEnvironmentFetch(environment, apiUrl);
  const { endpointFetch } = getEntityEndpoint(
    createOperation,
    options?.fetchFn ?? environmentFetch,
  );

  const body = JSON.stringify({
    data: injectOperationEntityMetadataRequiredFields(data, createOperation),
  });

  return useMutation({
    mutationFn: (mutateBody) =>
      endpointFetch({
        body: mutateBody || body,
        headers,
      }),
    onSuccess: (responseData) =>
      options?.onSuccess?.(responseData, environment),
    onError: options?.onError,
    onMutate: () => options?.onMutate?.(operation, environment),
    onSettled: options?.onSettled,
  });
};
