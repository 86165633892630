import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  UtilityText,
  Modal,
  Button,
  TextAction,
  HeadingText,
  HeadingBar,
} from '@leagueplatform/genesis-core';
import type { EntityDetail } from '@web-config-app/core';
import { useFormatEntityTableData } from './use-format-entity-table-data';

const DataCell = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    as="td"
    css={{
      padding: '$half',
      borderStyle: 'solid',
      borderColor: '$onSurfaceBorderSubdued',
      borderWidth: '$thin',
      width: '50%',
      maxWidth: 550,
      wordWrap: 'break-word',
    }}
  >
    <UtilityText>{children}</UtilityText>
  </Box>
);

const TableHeaderCell = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    as="th"
    scope="col"
    css={{
      backgroundColor: '$surfaceBackgroundTertiary',
      textAlign: 'left',
      padding: '$half',
      width: '50%',
    }}
  >
    <UtilityText>{children}</UtilityText>
  </Box>
);

export const EntityDataTableModal = ({
  data,
}: {
  data: Partial<EntityDetail> | undefined;
}) => {
  const { formatMessage } = useIntl();
  const dataRows = useFormatEntityTableData(data);
  return (
    <Modal.Root>
      <Modal.Trigger>
        <TextAction as="button" size="medium" icon="illustrativeDocument">
          {formatMessage({ id: 'VIEW_ALL_DATA' })}
        </TextAction>
      </Modal.Trigger>

      <Modal.Content layout="fullscreen" showCloseButton={false}>
        <HeadingBar
          rightAction={
            <Modal.Close>
              <Button
                priority="tertiary"
                quiet
                icon="tinyClose"
                hideLabel
                size="small"
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
          }
          title={
            <Modal.Title>
              <HeadingText level="3" size="md">
                {formatMessage({ id: 'VIEW_ALL_DATA' })}
              </HeadingText>
            </Modal.Title>
          }
        />
        <Box
          css={{
            padding: '$half',
            borderColor: '$onSurfaceBorderSubdued',
            borderWidth: 0,
            borderWidthTop: '$thin',
            borderStyle: 'solid',
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'stretch',
          }}
        >
          <Box
            as="table"
            css={{
              borderRadius: '$small',
              borderColor: '$onSurfaceBorderSubdued',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderSpacing: 0,
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Box as="thead">
              <Box as="tr">
                <TableHeaderCell>
                  {formatMessage({ id: 'PATH' })}
                </TableHeaderCell>
                <TableHeaderCell>
                  {formatMessage({ id: 'DATA' })}
                </TableHeaderCell>
              </Box>
            </Box>
            <Box as="tbody">
              {dataRows.length > 0 ? (
                dataRows.map(({ path, data: rowData }) => (
                  <Box as="tr" key={path}>
                    <DataCell>{path}</DataCell>
                    <DataCell>{rowData}</DataCell>
                  </Box>
                ))
              ) : (
                <Box as="tr">
                  <Box as="td" colSpan={2}>
                    <UtilityText emphasis="subtle">
                      {formatMessage({ id: 'NO_DATA' })}
                    </UtilityText>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
};
