import * as React from 'react';
import {
  Domain,
  DomainConfig,
  DomainMap,
  EntityConfig,
} from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

export const useDomains = (): DomainMap => {
  const { domains } = useConfigApp();

  /**
   * To make it easier to look-up domains and their entities by their id, we
   * transform the data structure from simple arrays to a Map which provides a nice
   * API for getting items by an id with `.get()`
   *
   * Maps utilize a slightly more complicated structure, ex:
   *
   * const map = [
   *  ['key-1', 'some-value']
   *  ['key-2', 'some-other-value']
   *  ...
   * ]
   *
   * which is maybe a bit weird BUT we can do, for example: `domain.get(entityId)` to find the matching
   * item vs the plain array method of `domain.entities.find(entity => entityId === entityId)
   *
   * The other complication is that if we want to use the map like an array, we first need to convert it
   * to one with `Array.from(yourMapHere)`
   *
   * In practice, I don't think we will be interacting with these maps other than in the `useDomain` and
   * `useEntity` hooks, so this shouldn't produce any friction.
   *
   * [See Map API]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map}
   *
   */

  return React.useMemo(
    () =>
      new Map<string, Domain>(
        domains.map(({ entities, ...domain }: DomainConfig) => [
          domain.id,
          {
            ...(domain as Domain),
            entities: new Map<string, EntityConfig>(
              entities.map((entity: EntityConfig) => [entity.id, entity]),
            ),
          },
        ]),
      ),
    [domains],
  );
};
