import * as React from 'react';
import { AccordionSingleItem } from '../../accordion-single-item/accordion-single-item.component';
import { NumberIcon } from '../../number-icon/number-icon.component';
import { ArrayActionButtons } from '../../array-action-buttons/array-action-buttons.component';
import type { PrimitiveObjectArrayItemProps } from '../../../types/components';

export const PrimitiveObjectArrayItem = ({
  uniqueId,
  children,
  itemLabel,
  itemNumber,
  itemData,
  path,
  handleOnMoveDown,
  handleOnMoveUp,
  handleRemoveItem,
  handleDuplicateItem,
  index,
  arrayLength,
}: React.PropsWithChildren<PrimitiveObjectArrayItemProps>) => (
  <AccordionSingleItem
    id={uniqueId}
    headingLevel="display"
    accordionHeading={
      <>
        <NumberIcon number={itemNumber} css={{ margin: 'auto $half' }} />
        {itemLabel}
      </>
    }
    additionalActions={
      <ArrayActionButtons
        showDeleteModal
        onDuplicate={handleDuplicateItem(itemData)}
        onDelete={handleRemoveItem(path, index)}
        onMoveDown={handleOnMoveDown(path, index)}
        onMoveUp={handleOnMoveUp(path, index)}
        itemLabel={itemLabel as string}
        itemIndex={index}
        arrayLength={arrayLength}
      />
    }
  >
    {children}
  </AccordionSingleItem>
);
