import { validateTenantConfig } from './validate-tenant-config/validate-tenant-config';
import { getCreateTenantConfigInfo } from '../configs/tenant-configs';
import { createTenantConfig } from './create-tenant-config';
import { isValidTenantId } from './is-valid-tenant-id';

export const getTenantConfig = (tenantId: string) => {
  if (isValidTenantId(tenantId)) {
    const tenantConfigInfo = getCreateTenantConfigInfo(tenantId);
    const tenantConfig = createTenantConfig({ tenantId, ...tenantConfigInfo });
    return validateTenantConfig(tenantConfig);
  }
  return undefined;
};
