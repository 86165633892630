import { isEntity } from '@web-config-app/core';
import { useEntitySchema } from '../use-entity-schema/use-entity-schema';
import { useEntityConfig } from '../use-entity-config/use-entity-config';

interface UseEntityProps {
  domainId: string | undefined;
  entityId: string | undefined;
  schemaPath?: string;
}

export const useEntity = ({
  domainId,
  entityId,
  schemaPath = '/schemas',
}: UseEntityProps) => {
  const entityConfig = useEntityConfig({ domainId, entityId });
  const entitySchema = useEntitySchema({ entityId, schemaPath });

  const entity = {
    ...entityConfig,
    schema: entitySchema,
  };

  return entity.schema && isEntity(entity) ? entity : null;
};
