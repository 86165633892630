import {
  UseEntityGetOptions,
  useEntityReference,
  useFindEntityInstanceById,
} from '@web-config-app/core-react';
import { useEntityDetailsProps } from '../../../../hooks/use-entity-details-props/use-entity-details-props';

export const useGetImageDataById = (
  imageId: string,
  options: UseEntityGetOptions | undefined,
) => {
  const configAssetEntityConfig = useEntityReference({
    entityType: 'config-asset',
  });
  const { currentAuthoringEnvironment } = useEntityDetailsProps();
  return useFindEntityInstanceById({
    entity: configAssetEntityConfig,
    instanceId: imageId,
    options,
    environment: currentAuthoringEnvironment,
  });
};
