import React, { FC } from 'react';
import {
  HeadingBar,
  Divider,
  StackLayout,
  Button,
  SkeletonText,
  SkeletonRectangle,
  focusOutlineInner,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import type {
  EntityDetailActionType,
  WorkflowAction,
  StatusBadge,
  EnvironmentKey,
  Nullable,
} from '@web-config-app/core';
import type {
  MultiEnvironmentActions,
  AvailableEnvironment,
} from '@web-config-app/core-react';
import { EntityDetailsRightHeaderActions } from '../entity-details-right-header-actions/entity-details-right-header-actions.component';
import { EntityNameAndSaveStatus } from '../entity-name-and-save-status/entity-name-and-save-status.component';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { EntityActionButton } from '../entity-action-button/entity-action-button.component';
import { EntityActionButtonModal } from '../entity-action-button-modal/entity-action-button-modal.component';

export interface EntityDetailsHeaderProps {
  menuItemGroup: {
    menuItems: {
      id: EntityDetailActionType;
      label: React.ReactNode;
    }[];
    onMenuItemClicked: (itemId: EntityDetailActionType) => void;
  };
  isLoading: boolean;
  secondaryAction: WorkflowAction;
  primaryAction: WorkflowAction;
  statusBadge?: StatusBadge;
  entityInstanceName?: string;
  entityConfigName: string;
  exitModalPrimaryButtonPath: string;
  saveStatusMessage?: string;
  isEntitySaved: boolean;
  isArchived: boolean;
  instanceId?: string;
  currentAuthoringEnvironment: EnvironmentKey;
  availableEnvironments: Nullable<AvailableEnvironment[]>;
  multiEnvironmentActions?: MultiEnvironmentActions;
  multiEnvironmentAuthoringEnabled: boolean;
  isNameEditable: boolean;
  setEntityName: (name: string) => void;
}

const commonButtonCssStyles: GDSStyleObject = {
  padding: '$oneAndHalf',
  border: 0,
  '&[class*="GDS"]:focus': {
    outlineOffset: 0,
    outline: 0,
  },
  '&:focus': {
    ...focusOutlineInner,
  },
};

const EntityDetailsHeaderTriggerLink = ({
  entityListPath,
}: {
  entityListPath: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      icon="tinyClose"
      hideLabel
      priority="tertiary"
      quiet
      size="toolbar"
      as={Link}
      to={entityListPath}
      css={{ ...commonButtonCssStyles }}
    >
      {formatMessage({ id: 'EXIT' })}
    </Button>
  );
};

export const EntityDetailsHeaderSkeleton = () => (
  <HeadingBar
    css={{
      width: '100%',
    }}
    leftAction={
      <StackLayout spacing="$half" css={{ minWidth: 300 }}>
        <SkeletonText header />
        <SkeletonText />
      </StackLayout>
    }
    rightAction={
      <StackLayout
        orientation="horizontal"
        spacing="$one"
        verticalAlignment="center"
      >
        <SkeletonText width={100} />
        <SkeletonRectangle height={38} width={120} />
        <SkeletonRectangle height={38} />
      </StackLayout>
    }
  />
);

export const EntityDetailsHeader: FC<EntityDetailsHeaderProps> = ({
  menuItemGroup,
  secondaryAction,
  primaryAction,
  statusBadge,
  exitModalPrimaryButtonPath,
  isLoading = false,
  entityInstanceName,
  entityConfigName,
  saveStatusMessage,
  isArchived,
  isEntitySaved,
  instanceId,
  currentAuthoringEnvironment,
  availableEnvironments,
  multiEnvironmentActions,
  multiEnvironmentAuthoringEnabled,
  isNameEditable,
  setEntityName,
}) => {
  const { formatMessage } = useIntl();
  const exitModalTitle = formatMessage(
    { id: 'EXIT_ENTITY_DETAIL_TITLE' },
    { entity: entityConfigName },
  );
  const exitModalDescription = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
  });

  const confirmCtaText = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
  });

  const primaryActionSharedProps = {
    buttonPriority: 'primary' as const,
    label: primaryAction.label,
    display: primaryAction.display,
    action: primaryAction.action,
    disabled: primaryAction.disabled,
    modal: primaryAction.modal,
  };

  const secondaryActionSharedProps = {
    buttonPriority: 'secondary' as const,
    label: secondaryAction.label,
    display: secondaryAction.display,
    action: secondaryAction.action,
    disabled: secondaryAction.disabled,
    modal: secondaryAction.modal,
  };
  return (
    <StackLayout
      orientation="horizontal"
      css={{ alignItems: 'center', height: '100%' }}
    >
      {!isEntitySaved ? (
        <ConfirmationModal
          modalTrigger={
            <Button
              icon="tinyClose"
              hideLabel
              priority="tertiary"
              quiet
              size="toolbar"
              css={{ ...commonButtonCssStyles }}
            >
              {formatMessage({ id: 'EXIT' })}
            </Button>
          }
          title={exitModalTitle}
          description={exitModalDescription}
          confirmCtaText={confirmCtaText}
          confirmCtaAction={exitModalPrimaryButtonPath}
        />
      ) : (
        <EntityDetailsHeaderTriggerLink
          entityListPath={exitModalPrimaryButtonPath}
        />
      )}
      <Divider orientation="vertical" css={{ margin: 0 }} />
      {isLoading ? (
        <EntityDetailsHeaderSkeleton />
      ) : (
        <HeadingBar
          css={{
            width: '100%',
            '.GDS-heading-bar-base': {
              paddingX: '$oneAndQuarter',
              gap: '$one',
            },
          }}
          leftAction={
            <EntityNameAndSaveStatus
              isNameEditable={isNameEditable}
              setEntityName={setEntityName}
              entityInstanceName={entityInstanceName}
              instanceId={instanceId}
              isArchived={isArchived}
              isEntitySaved={isEntitySaved}
              saveStatusMessage={saveStatusMessage}
            />
          }
          rightAction={
            <EntityDetailsRightHeaderActions
              entityConfigName={entityConfigName}
              currentAuthoringEnvironment={currentAuthoringEnvironment}
              availableEnvironments={availableEnvironments}
              multiEnvironmentActions={multiEnvironmentActions}
              multiEnvironmentAuthoringEnabled={
                multiEnvironmentAuthoringEnabled
              }
              statusBadge={statusBadge}
              menuItemGroup={menuItemGroup}
              primaryAction={
                primaryAction?.modal ? (
                  <EntityActionButtonModal {...primaryActionSharedProps} />
                ) : (
                  <EntityActionButton {...primaryActionSharedProps} />
                )
              }
              secondaryAction={
                secondaryAction?.modal ? (
                  <EntityActionButtonModal {...secondaryActionSharedProps} />
                ) : (
                  <EntityActionButton {...secondaryActionSharedProps} />
                )
              }
            />
          }
        />
      )}
    </StackLayout>
  );
};
