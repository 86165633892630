import {
  rankWith,
  and,
  or,
  isStringControl,
  isNumberControl,
  isBooleanControl,
  isIntegerControl,
  formatIs,
  isObjectControl,
  isEnumControl,
  isPrimitiveArrayControl,
  UISchemaElement,
  JsonSchema,
  TesterContext,
  ControlElement,
  resolveSchema,
  schemaMatches,
  schemaTypeIs,
} from '@jsonforms/core';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import {
  isPrimitiveObjectSchema,
  isCombinatorObjectSchema,
  isCombinatorArraySchema,
} from '@web-config-app/schema-utils';
import { TesterRank } from './tester-rank';

/** To find a matching renderer, JSON Forms relies on so-called testers. Every renderer has a tester associated with its registration, which is a function of a UI schema and a JSON schema returning a number. The returned number is the priority which expresses if and how well a renderer can actually render the given UI Schema Element (where NOT_APPLICABLE aka. -1 means "not at all").
 *
 */

/**
 * JsonSchema testers use a `rank` to break ties in instances where multiple testers return `true` for the
 * same property. As an example, for a property with `type: 'string'` and `format: 'date'`, both the
 * `isTextInputControl` and `isDateInputControl` testers below will return true. By giving `isDateInputControl`
 * a higher rank, it will be the matcher used to return the property renderer.
 */

/**
 * Internal tester helpers
 */

const isCombinatorArrayOfComplexObjects = (
  uischema: UISchemaElement,
  schema: JsonSchema,
  context: TesterContext,
) => {
  const schemaPath = (uischema as ControlElement).scope;
  const resolvedSchema = resolveSchema(
    schema,
    schemaPath,
    context?.rootSchema ?? schema,
  );
  const { oneOf } = resolvedSchema;
  return Array.isArray(oneOf)
    ? oneOf.some(
        (subSchema: AnnotatedJsonSchema) => !isPrimitiveObjectSchema(subSchema),
      )
    : false;
};

/**
 * Primitive Control Testers
 */

// type: string
export const isTextInputControl = rankWith(TesterRank.Base, isStringControl);
export const isTextareaControl = rankWith(
  TesterRank.Higher,
  and(
    isStringControl,
    schemaMatches(
      (schema) =>
        (schema as AnnotatedJsonSchema)['x-entity-control']?.type ===
        'textarea',
    ),
  ),
);

export const isDateInputControl = rankWith(
  TesterRank.Higher,
  // has type: string AND format: `date`
  and(isStringControl, formatIs('date')),
);

export const isDateTimeInputControl = rankWith(
  TesterRank.Higher,
  // has type: string AND format: date-time
  and(isStringControl, formatIs('date-time')),
);

export const isRichTextInputControl = rankWith(
  TesterRank.Higher,
  // has type: string AND format: rich-text
  and(isStringControl, formatIs('rich-text')),
);

export const isJsonLogicInputControl = rankWith(
  TesterRank.Higher,
  // has type: string AND format: json-logic
  and(isStringControl, formatIs('json-logic')),
);

export const isSelectInputControl = rankWith(
  TesterRank.Higher,
  or(
    isEnumControl,
    (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext) => {
      const schemaPath = (uischema as ControlElement).scope;
      const resolvedSchema = resolveSchema(
        schema,
        schemaPath,
        context?.rootSchema ?? schema,
      ) as AnnotatedJsonSchema;
      return (
        Boolean(resolvedSchema['x-entity-data-source']) &&
        resolvedSchema.type === 'string'
      );
    },
  ),
);

// type: number
export const isNumberInputControl = rankWith(TesterRank.Base, isNumberControl);

// type: integer
export const isIntegerInputControl = rankWith(
  TesterRank.Base,
  isIntegerControl,
);

// type: boolean
export const isBooleanInputControl = rankWith(
  TesterRank.Base,
  isBooleanControl,
);

const isImageControl = (
  uischema: UISchemaElement,
  schema: JsonSchema,
  context: TesterContext,
) => {
  const schemaPath = (uischema as ControlElement).scope;
  const resolvedSchema = resolveSchema(
    schema,
    schemaPath,
    context?.rootSchema ?? schema,
  ) as AnnotatedJsonSchema;
  return resolvedSchema['x-entity-asset']?.type === 'image';
};

// type: image
export const isImageInputControl = rankWith(
  TesterRank.Highest,
  and(isStringControl, isImageControl),
);

/**
 * Object Control Testers
 */

// type: object
export const isObjectDispatchControl = rankWith(
  TesterRank.Base,
  isObjectControl,
);

export const isObjectCard = rankWith(
  TesterRank.Highest,
  schemaMatches(
    (schema) =>
      (schema as AnnotatedJsonSchema)['x-entity-control']?.type ===
      'objectCard',
  ),
);

/**
 * Array Control Testers
 */

export const isArrayObjectPrimitiveDispatchControl = rankWith(
  TesterRank.Highest,
  schemaMatches((schema) => {
    if (schema.type !== 'array' || !schema.items) return false;
    const itemSchema = schema.items as AnnotatedJsonSchema;
    if (
      isCombinatorObjectSchema(itemSchema) ||
      isCombinatorArraySchema(schema as AnnotatedJsonSchema)
    ) {
      return false;
    }

    if (itemSchema['x-entity-data-source']?.dataSource) return false;

    return isPrimitiveObjectSchema(itemSchema);
  }),
);

/**
 * array if Object with reference data source control.
 * This is right now just used for Masonry's resolver use case
 * */

export const isArrayObjectReferenceInjectControl = rankWith(
  TesterRank.Highest,
  schemaMatches((schema) => {
    if (schema.type !== 'array' || !schema.items) return false;
    const itemSchema = schema.items as AnnotatedJsonSchema;
    const annotatedSchema = schema as AnnotatedJsonSchema;
    if (
      isCombinatorObjectSchema(itemSchema) ||
      isCombinatorArraySchema(annotatedSchema)
    ) {
      return false;
    }

    return Boolean(
      isPrimitiveObjectSchema(itemSchema) &&
        itemSchema['x-entity-data-source']?.dataSource,
    );
  }),
);

export const isArrayObjectComplexDispatchControl = rankWith(
  TesterRank.Highest,

  schemaMatches((schema) => {
    if (schema.type !== 'array' || !schema.items) {
      return false;
    }

    const itemSchema = schema.items as AnnotatedJsonSchema;

    const isItemSchemaCombinatorOrComplexObjectSchema =
      itemSchema.type === 'object' &&
      (!isPrimitiveObjectSchema(itemSchema) ||
        isCombinatorObjectSchema(itemSchema));

    return (
      isItemSchemaCombinatorOrComplexObjectSchema ||
      isCombinatorArraySchema(schema as AnnotatedJsonSchema)
    );
  }),
);

export const isArrayPrimitiveDispatchControl = rankWith(
  TesterRank.Higher,
  isPrimitiveArrayControl,
);

/**
 * Entity Reference
 */

export const isEntityReferenceInjectedControl = rankWith(
  TesterRank.Highest + 1,
  (uischema, schema, context) => {
    const schemaPath = (uischema as ControlElement).scope;
    const resolvedSchema = resolveSchema(
      schema,
      schemaPath,
      context?.rootSchema ?? schema,
    ) as AnnotatedJsonSchema;
    return Boolean(
      resolvedSchema.type === 'object' &&
        resolvedSchema['x-entity-reference']?.relationship,
    );
  },
);

export const isEntityReferenceStringControl = rankWith(
  TesterRank.Highest + 1,
  (uischema, schema, context) => {
    const schemaPath = (uischema as ControlElement).scope;
    const resolvedSchema = resolveSchema(
      schema,
      schemaPath,
      context?.rootSchema ?? schema,
    ) as AnnotatedJsonSchema;
    return Boolean(
      resolvedSchema.type === 'string' &&
        resolvedSchema['x-entity-reference']?.relationship,
    );
  },
);

export const isArrayEntityReferenceControl = rankWith(
  TesterRank.Highest,
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext) => {
    const schemaPath = (uischema as ControlElement).scope;
    const resolvedSchema = resolveSchema(
      schema,
      schemaPath,
      context?.rootSchema ?? schema,
    );
    if (resolvedSchema.type !== 'array' || !resolvedSchema.items) return false;

    const itemsSchema = resolvedSchema?.items as AnnotatedJsonSchema;
    return Boolean(itemsSchema['x-entity-reference']?.relationship);
  },
);

/**
 * Combinator Control Testers
 */

// The built-in isOneOfControl testers are specifically for arrays
// so we need to add these custom testers for oneOf objects

const isOneOfControl = (
  uischema: UISchemaElement,
  schema: JsonSchema,
  context: TesterContext,
): boolean => {
  const schemaPath = (uischema as ControlElement).scope;
  const resolvedSchema = resolveSchema(
    schema,
    schemaPath,
    context?.rootSchema ?? schema,
  );

  return Boolean(resolvedSchema.oneOf);
};

export const isCombinatorObjectInputControl = rankWith(
  TesterRank.Higher,
  and(isObjectControl, isOneOfControl),
);

export const isCombinatorArrayItemPageControl = rankWith(
  TesterRank.Base,
  isCombinatorArrayOfComplexObjects,
);

export const isCombinatorOneOfArrayInputControl = rankWith(
  TesterRank.Base,
  and(schemaTypeIs('array'), isOneOfControl),
);
