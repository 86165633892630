import { useCallback } from 'react';
import { useConfigProperty } from '@leagueplatform/config';
import type { EnvironmentKey } from '@web-config-app/core';
import {
  getTokenForAudience,
  getUrlFromPath,
  configFetch,
} from '@web-config-app/api';
import type { ConfigFetchOptions } from '@web-config-app/api';
import { useAuthoringEnvironmentInfo } from '../use-authoring-environment-info/use-authoring-environment-info';

export type UseEnvironmentFetchOptions = Omit<
  ConfigFetchOptions,
  'token' | 'appVersion' | 'clientId'
>;

/**
 * Returns a function for making requests on a specific environment
 *
 * @param type the {@link AuthoringEnvironmentType} you wish to make fetch requests against
 */

export const useEnvironmentFetch = (
  environment: EnvironmentKey,
  entityBaseUrl?: string,
) => {
  const { apiUrl, audience } = useAuthoringEnvironmentInfo(environment);
  const { clientId, appVersion } = useConfigProperty('core');
  return useCallback(
    async (
      path: string,
      options?: UseEnvironmentFetchOptions,
      fetchFn: (
        path: string,
        options: ConfigFetchOptions,
      ) => Promise<Response> = configFetch,
    ) => {
      const token = await getTokenForAudience(audience);
      const url = getUrlFromPath(path, entityBaseUrl ?? apiUrl);
      const result = await fetchFn(url, {
        ...options,
        appVersion,
        clientId,
        token,
      });

      return result;
    },
    [entityBaseUrl, apiUrl, audience, clientId, appVersion],
  );
};
