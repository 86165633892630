import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  AppHeader,
  UserProfilePopover,
  EnvironmentInfoPopover,
} from '@web-config-app/core-react-ui';
import {
  useUserNameAndEmail,
  useConfigApp,
  useAuthoringEnvironments,
  useAuthoringEnvironmentInfo,
} from '@web-config-app/core-react';
import type { EnvironmentKey } from '@web-config-app/core';

export const AppHeaderContainer = ({
  currentEnvironment,
}: {
  currentEnvironment?: EnvironmentKey;
}) => {
  const { tenantLogo, tenantName } = useConfigApp();
  const { baseAuthoringEnvironment, multiEnvironmentAuthoringEnabled } =
    useAuthoringEnvironments();
  const authoringEnvironmentInfo =
    useAuthoringEnvironmentInfo(currentEnvironment);
  const { formatMessage } = useIntl();

  const history = useHistory();
  const { userName, userEmail } = useUserNameAndEmail();

  return (
    <AppHeader
      logo={tenantLogo}
      tenant={tenantName}
      environmentLabel={
        !multiEnvironmentAuthoringEnabled
          ? formatMessage({ id: baseAuthoringEnvironment.nameKey })
          : undefined
      }
    >
      <EnvironmentInfoPopover
        environment={authoringEnvironmentInfo}
        multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
      />
      <UserProfilePopover
        triggerLabel={formatMessage({ id: 'PROFILE' })}
        userName={userName}
        userEmail={userEmail}
        ctaLabel={formatMessage({ id: 'LOG_OUT' })}
        ctaAction={() => history.push('/sign-out')}
      />
    </AppHeader>
  );
};
