import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { EntityDetailsHeader } from '@web-config-app/core-react-ui';
import {
  useEntityDetailsProps,
  useEntityWorkflowActions,
  useEntityHeaderSaveStatus,
  EntityMenuItem,
} from '@web-config-app/entity-form';
import {
  getEntityStatusBadgeProps,
  EntityDetailActionType,
  EntityStatusValue,
} from '@web-config-app/core';
import type { EnvironmentKey, Entity } from '@web-config-app/core';
import { useEntityDetailsEnvironmentSelect } from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';

interface EntityDetailsHeaderContainerProps {
  isLoading: boolean;
  entity: Entity;
  entityListPath: string;
  environmentKey: EnvironmentKey;
  environmentName: string;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  setEntityName: (name: string) => void;
  publishDangerously: boolean;
}

export const EntityDetailsHeaderContainer = ({
  isLoading = false,
  entity: { nameTranslationKey, schema },
  entityListPath,
  environmentKey,
  getEntityDetailsPathForEnvAndId,
  setEntityName,
  publishDangerously,
  environmentName,
}: EntityDetailsHeaderContainerProps) => {
  const { formatMessage } = useIntl();

  const entityConfigName = formatMessage(
    { id: nameTranslationKey },
    { count: 1 },
  );

  const {
    operations,
    name,
    status,
    instanceId,
    entityRootData,
    otherAuthoringEnvironmentState,
    isReadOnly,
  } = useEntityDetailsProps();

  const { isEntitySaved, message: saveStatusMessage } =
    useEntityHeaderSaveStatus({
      operations,
      entityRootData,
      status,
    });

  const {
    currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
    multiEnvironmentActions,
  } = useEntityDetailsEnvironmentSelect({
    status,
    entityName: entityConfigName,
    getEntityDetailsPathForEnvAndId,
    environmentKey,
    entityInstanceId: instanceId,
    otherAuthoringEnvironmentState,
    isEntitySaved,
  });

  const {
    primaryActionInterface,
    secondaryActionInterface,
    menuItems: workflowMenuItems = [],
  } = useEntityWorkflowActions({
    operations,
    status,
    isEntitySaved,
    entityConfigName,
    entityListPath,
    environmentName,
    publishDangerously,
    isReadOnly,
  });

  const statusBadgeProps = getEntityStatusBadgeProps(status);

  const statusBadge = {
    label: formatMessage({ id: statusBadgeProps?.labelKey }),
    status: statusBadgeProps?.genesisStatus,
  };

  const isArchived = status === EntityStatusValue.Archived;

  const menuItems = workflowMenuItems.map((item) => ({
    id: item?.type as EntityDetailActionType,
    label: <EntityMenuItem item={item} />,
  }));

  const onMenuItemClicked = React.useCallback(
    (itemId: EntityDetailActionType) =>
      workflowMenuItems.find((action) => action.type === itemId)?.action?.(),
    [workflowMenuItems],
  );

  const menuItemGroup = { menuItems, onMenuItemClicked };

  const entityInstanceName =
    name || `${formatMessage({ id: 'UNTITLED' })} ${entityConfigName}`;

  /**
   * Check if the entity adds a custom path to the entity name or whether
   * it uses the {@link EntityMetadata} interface.
   */
  const isNameInEntityMetadata = !schema['x-entity-metadata']?.entityNamePath;

  return (
    <EntityDetailsHeader
      isLoading={isLoading}
      exitModalPrimaryButtonPath={entityListPath}
      menuItemGroup={menuItemGroup}
      secondaryAction={secondaryActionInterface}
      primaryAction={primaryActionInterface}
      statusBadge={statusBadge}
      entityInstanceName={entityInstanceName}
      isEntitySaved={isEntitySaved}
      saveStatusMessage={saveStatusMessage}
      isArchived={isArchived}
      entityConfigName={entityConfigName}
      instanceId={instanceId}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      availableEnvironments={availableEnvironments}
      multiEnvironmentActions={multiEnvironmentActions}
      isNameEditable={isNameInEntityMetadata}
      setEntityName={setEntityName}
      multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
    />
  );
};
