import { AnnotatedJsonSchema } from '@web-config-app/core';

export const mockSchemaEntityPresentation = {
  hint: {
    key: 'MOCK_INPUT_HINT',
  },
  banner: {
    title: {
      key: 'MOCK_INPUT_BANNER_TITLE',
    },
    status: 'warning',
    description: {
      key: 'MOCK_INPUT_BANNER_DESCRIPTION',
    },
  },
} as const;

export const mockSchemaEntityWithEnumLabels = {
  enum: ['value1', 'value2'],
  'x-entity-presentation': {
    ...mockSchemaEntityPresentation,
    enumLabels: {
      enumControlPlaceholder: 'ENUM_CONTROL_PLACEHOLDER',
      value1: 'VALUE_ONE',
      value2: 'VALUE_TWO',
    },
  },
};

export const mockSchemaEntityWithFieldOrderAndFieldGroups = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    address1: {
      type: 'string',
    },
    city: {
      type: 'string',
    },
    province: {
      type: 'string',
    },
  },
  'x-entity-presentation': {
    ...mockSchemaEntityPresentation,
    fieldOrder: ['firstName', 'lastName', 'address'],
    fieldGroups: [
      {
        fields: ['address1', 'city', 'province'],
      },
    ],
  },
};

export const mockSchemaEntityPresentationWithArrayAddLabel = {
  ...mockSchemaEntityPresentation,
  arrayAddLabel: 'MOCK_ARRAY_ADD_LABEL',
} as const;

export const mockPropertySchema = {
  type: 'string',
  title: 'Text',
  description: 'This is where you enter text',
  'x-entity-label': {
    key: 'MOCK_BASE_INPUT_CONTROL_LABEL',
    propertyRef: 'some.value.path',
  },
  'x-entity-presentation': mockSchemaEntityPresentation,
};

export const mockSchema = {
  type: 'object',
  properties: {
    text: mockPropertySchema,
  },
} as const;

export const mockControlProps = {
  schema: mockSchema.properties.text,
  rootSchema: mockSchema,
  description: mockSchema.properties.text.description,
  label: mockSchema.properties.text.title,
  data: 'hai',
  visible: true,
  id: 'property_id',
  uischema: { type: 'Control', scope: 'mock-scope' },
  errors: 'errors',
  required: false,
  enabled: true,
  path: 'text',
};

const createMockControlPropsWithSchema = (schema: any) => ({
  ...mockControlProps,
  schema,
  description: schema.description,
  label: schema.title,
});

const createMockPropertySchemaWithAnnotations = (annotations: any) => ({
  ...mockPropertySchema,
  ...annotations,
});

export const mockControlPropsWithEnumLabels = createMockControlPropsWithSchema(
  createMockPropertySchemaWithAnnotations(mockSchemaEntityWithEnumLabels),
);

export const mockControlPropsWithFieldOrderAndFieldGroups =
  createMockControlPropsWithSchema(
    mockSchemaEntityWithFieldOrderAndFieldGroups,
  );

export const mockControlPropsWithArrayAddLabel =
  createMockControlPropsWithSchema(
    createMockPropertySchemaWithAnnotations(
      mockSchemaEntityPresentationWithArrayAddLabel,
    ),
  );

export const mockArrayItemWithDatasourceForPropertyInjection = {
  type: 'object',
  title: 'An array item object with injectable',
  'x-entity-label': {
    key: 'MOCK_PRIMITIVE_OBJECT_LABEL_KEY',
  },
  'x-entity-data-source': {
    dataSource: 'test',
    schemaReferenceProperty: 'test',
    sourcePropertyPath: 'test-property',
  },
  properties: {
    'test-property': {
      type: 'string',
      'x-entity-label': {
        key: 'MOCK_SIMPLE_SCHEMA_PRIMITIVE_TEXT_LABEL',
      },
    },
  },
};

export const mockObjectPrimitiveInArraySchema = {
  type: 'object',
  title: 'A primitive object',
  'x-entity-label': {
    key: 'MOCK_PRIMITIVE_OBJECT_LABEL_KEY',
  },
  'x-entity-presentation': {
    hint: {
      key: 'MOCK_PRIMITIVE_OBJECT_HINT',
    },
    banner: {
      title: {
        key: 'MOCK_BANNER_TITLE_IMPORTANT',
      },
      status: 'info',
      description: {
        key: 'MOCK_PRIMITIVE_OBJECT_BANNER_DESCRIPTION',
      },
    },
  },
  properties: {
    string: {
      title: 'String',
      'x-entity-label': {
        key: 'MOCK_SIMPLE_SCHEMA_PRIMITIVE_TEXT_LABEL',
      },
      type: 'string',
    },
    showInteger: {
      'x-entity-label': {
        key: 'MOCK_CONDITIONAL_CHECKBOX_LABEL',
      },
      title: 'Show integer',
      type: 'boolean',
    },
    integer: {
      'x-entity-conditional': {
        conditions: [
          {
            type: 'includeIf',
            rules: {
              '==': [{ var: 'showInteger' }, true],
            },
          },
        ],
      },
      title: 'Integer Input Control',
      'x-entity-label': {
        key: 'MOCK_INTEGER_INPUT_LABEL',
      },
      type: 'integer',
      multipleOf: 1,
    },
  },
} as AnnotatedJsonSchema;

export const mockRootArrayObjectPrimitiveSchema = {
  type: 'object',
  properties: {
    coolArray: {
      type: 'array',
      title: 'Mock array object primitive',
      'x-entity-label': {
        key: 'MOCK_ARRAY_PRIMITIVE_OBJECT_LABEL',
      },
      'x-entity-presentation': {
        hint: {
          key: 'MOCK_ARRAY_PRIMITIVE_OBJECT_HINT',
        },
        banner: {
          title: {
            key: 'MOCK_ARRAY_PRIMITIVE_BANNER_TITLE',
          },
          status: 'info',
          description: {
            key: 'MOCK_ARRAY_PRIMITIVE_BANNER_TEXT',
          },
        },
      },
      items: mockObjectPrimitiveInArraySchema,
    },
  },
} as AnnotatedJsonSchema;

const mockUISchemaArray = {
  type: 'Control',
  scope: '#/properties/coolArray',
};

export const mockArrayObjectPrimitiveControlProps = {
  schema: mockObjectPrimitiveInArraySchema,
  uischema: mockUISchemaArray,
  data: [],
  label: 'Array Label',
  id: '1',
  errors: '',
  rootSchema: mockRootArrayObjectPrimitiveSchema,
  enabled: true,
  visible: true,
  path: 'coolArray',
};

const mockPrimitiveInArray = {
  title: 'item',
  type: 'string',
  'x-entity-label': {
    key: 'MOCK_PRIMITIVE_ARRAY_ITEM_LABEL',
  },
  'x-entity-presentation': mockSchemaEntityPresentation,
};

export const mockRootArrayPrimitiveSchema = {
  type: 'object',
  title: 'Root Object',
  properties: {
    arrayPrimitive: {
      type: 'array',
      title: 'Mock array primitive',
      'x-entity-presentation': {
        hidden: false,
        hint: {
          key: 'MOCK_PRIMITIVE_ARRAY_HINT',
        },
        banner: {
          title: {
            key: 'MOCK_BANNER_TITLE_GOOD_TO_KNOW',
          },
          status: 'info',
          description: {
            key: 'MOCK_PRIMITIVE_ARRAY_BANNER_DESCRIPTION',
          },
        },
      },
      items: {
        string: {
          mockPrimitiveInArray,
        },
      },
    },
  },
} as const;

export const mockArrayPrimitiveControlProps = {
  schema: mockPrimitiveInArray,
  uischema: { ...mockUISchemaArray, scope: '#/properties/arrayPrimitive' },
  data: undefined,
  label: 'Array Label',
  id: '2',
  errors: '',
  rootSchema: mockRootArrayPrimitiveSchema,
  enabled: true,
  visible: true,
  path: 'arrayPrimitive',
};

export const mockComplexObjectsArrayItemSchema = {
  type: 'object',
  title: 'A complex object',
  'x-entity-label': {
    key: 'SIMPLE_SCHEMA_COMPLEX_OBJECT_LABEL',
    propertyRef: '$.title',
  },
  properties: {
    title: {
      title: 'Title',
      type: 'string',
    },
    artist: {
      type: 'string',
      title: 'Artist',
    },
    meta: {
      type: 'object',
      properties: {
        genre: {
          type: 'string',
          title: 'Genre',
          enum: ['emo', 'screamo', 'dreamo'],
        },
        year: {
          title: 'Year',
          type: 'number',
          minimum: 1996,
          maximum: 2077,
        },
      },
    },
  },
};

export const mockArrayOfComplexObjectsSchema = {
  type: 'array',
  title: 'Mock array of complex objects',
  'x-entity-label': {
    key: 'MOCK_ARRAY_COMPLEX_OBJECT_LABEL',
  },
  items: mockComplexObjectsArrayItemSchema,
};

export const mockRootArrayOfComplexObjectsSchema = {
  type: 'object',
  properties: {
    coolArray: mockArrayOfComplexObjectsSchema,
  },
};

export const mockArrayOfComplexObjectsControlProps = {
  schema: mockComplexObjectsArrayItemSchema,
  uischema: mockUISchemaArray,
  data: undefined,
  label: 'Array Label',
  id: '1',
  errors: '',
  rootSchema: mockRootArrayOfComplexObjectsSchema,
  enabled: true,
  visible: true,
  path: 'coolArray',
  itemLabelPropertyRef: 'title',
};
