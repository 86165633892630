import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import type { AuthoringEnvironmentClientInfo } from '@web-config-app/core';
import { StackLayout, UtilityText, Icon } from '@leagueplatform/genesis-core';

export interface EnvironmentInfoProps {
  environment: Pick<
    AuthoringEnvironmentClientInfo,
    'apiUrl' | 'audience' | 'type' | 'id'
  >;
  multiEnvironmentAuthoringEnabled?: boolean;
}

/**
 * A component that displays environment information in a structured layout.
 *
 * @component
 * @param {object} props - The component props
 * @param {AuthoringEnvironmentClientInfo} props.environment - Environment information containing API URL, audience, type, and id
 * @param {boolean} props.multiEnvironmentAuthoringEnabled - Flag indicating if multi-environment authoring is enabled
 *
 * @returns {React.ReactElement} A stack layout containing environment information displayed as a definition list
 *
 * @example
 * ```tsx
 * <EnvironmentInfo
 *   environment={{
 *     apiUrl: "https://api.example.com",
 *     audience: "example-audience",
 *     type: "production",
 *     id: "ca:production"
 *   }}
 *   multiEnvironmentAuthoringEnabled={true}
 * />
 * ```
 */

export const EnvironmentInfo: React.FC<EnvironmentInfoProps> = ({
  environment: { apiUrl, audience, type, id },
  multiEnvironmentAuthoringEnabled = false,
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      spacing="$one"
      as="dl"
      css={{
        typography: '$label',
        dt: { fontWeight: 'bold' },
        dd: {
          marginInlineStart: '$none',
          marginBlockStart: '$quarter',
          fontFamily: 'monospace',
        },
      }}
    >
      <UtilityText size="xs">
        <dt id="environment-label">
          {formatMessage({ id: 'ENVIRONMENT_LABEL' })}
        </dt>
        <dd aria-labelledby="environment-label">{id}</dd>
      </UtilityText>
      <UtilityText size="xs">
        <dt id="environment-api-url">
          {formatMessage({ id: 'API_URL_LABEL' })}{' '}
        </dt>
        <dd aria-labelledby="environment-api-url">{apiUrl}</dd>
      </UtilityText>
      <UtilityText size="xs">
        <dt id="environment-audience">
          {formatMessage({ id: 'AUDIENCE_LABEL' })}
        </dt>
        <dd aria-labelledby="environment-audience">{audience}</dd>
      </UtilityText>
      <UtilityText size="xs">
        <dt id="environment-type">
          {formatMessage({ id: 'ENVIRONMENT_TYPE_LABEL' })}
        </dt>
        <dd aria-labelledby="environment-type">{type}</dd>
      </UtilityText>
      {multiEnvironmentAuthoringEnabled && (
        <UtilityText size="xs">
          <dt id="multi-environment-enabled">
            {formatMessage({ id: 'MULTI_ENVIRONMENT_ENABLED_LABEL' })}
          </dt>
          <dd aria-labelledby="multi-environment-enabled">
            <Icon
              icon="tinyValidationCheck"
              tint="$successIcon"
              size="$one"
              label={formatMessage({ id: 'YES' })}
            />
          </dd>
        </UtilityText>
      )}
    </StackLayout>
  );
};
