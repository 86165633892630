import * as React from 'react';
import {
  Card,
  GDSHeadingLevel,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import EmptyDefaultImage from '../../assets/empty.png';

type EmptyStateCardProps = {
  heading: string;
  body: string;
  headingLevel?: GDSHeadingLevel;
  image?: string;
  hideImage?: boolean;
  imageAlt?: string;
};

export const EmptyStateCard: React.FC<EmptyStateCardProps> = ({
  heading,
  body,
  headingLevel = '2',
  image,
  hideImage = false,
  imageAlt = '',
}) => (
  <Card.Flat
    css={{
      border: '1px solid $onSurfaceBorderSubdued',
      padding: '$twoAndHalf',
      zIndex: -1,
    }}
  >
    {!hideImage && (
      <Card.Image
        image={image || EmptyDefaultImage}
        css={{ height: '$five:', width: '$five', margin: '0 auto' }}
        imageAlt={imageAlt}
      />
    )}
    <Card.Section css={{ textAlign: 'center' }}>
      <HeadingText size="lg" level={headingLevel}>
        {heading}
      </HeadingText>
      <ParagraphText>{body}</ParagraphText>
    </Card.Section>
  </Card.Flat>
);
